
import { createWebHistory, createRouter } from "vue-router";
import Login from "../views/auth/LoginPage";
import Home from "../views/HomePage";
import AddSupplier from "@/views/supplier/AddSupplier.vue";
import Suppliers from "@/views/supplier/Suppliers.vue";
import ViewSupplier from "@/views/supplier/ViewSupplier.vue";
import Warehouses from "@/views/warehouse/Warehouses.vue";
import AddWarehouse from "@/views/warehouse/AddWarehouse.vue";
import ViewWarehouse from "@/views/warehouse/ViewWarehouse.vue";
import VirtualWarehouse from "@/views/warehouse/VirtualWarehouse.vue";
import ProductWarehouse from "@/views/warehouse/ProductWarehouse.vue";
import LocateWarehouse from "../views/warehouse/LocateWarehouse.vue";
import ProductActivity from "../views/warehouse/ProductActivity.vue";
import AddProduct from "../views/inventory/products/AddProduct.vue";
import ViewProducts from "../views/inventory/products/ViewAllProducts.vue";
import ViewProduct from "../views/inventory/products/ViewProduct.vue";
import EditProduct from "../views/inventory/products/EditProduct.vue";
import ManageStocks from "../views/inventory/ManageStock.vue";
import StockAdjustment from '../views/inventory/StockAdjustment.vue';
import StockAdjustments from '../views/inventory/StockAdjustments.vue';
import StockTransfer from '../views/inventory/StockTransfer.vue';
import StockTransfers from '../views/inventory/StockTransfers.vue';
import StockReserved from '../views/inventory/StockReserved.vue';
import StockReserveds from '../views/inventory/StockReserveds.vue';
import StockReservedActivity from '../views/inventory/StockReservedActivity.vue';
import MarkedReturn from '../views/inventory/MarkedReturn.vue';
import Transaction from "../views/transaction/HomeTransactions.vue";
import AddBatch from "../views/transaction/batch/AddBatch.vue";
import SavedBatch from "../views/transaction/batch/SavedBatch.vue";
import ProcessedBatch from "../views/transaction/batch/ProcessedBatch.vue";
import PdfBatch from "../views/transaction/batch/PdfBatch.vue";
import IncompleteDelivery from "../views/transaction/idn/IncompleteDelivery.vue";
import AddIncompleteDelivery from "../views/transaction/idn/AddIncompleteDelivery.vue";
import PdfIncompleteDelivery from "../views/transaction/idn/IdnPdf.vue";
import SettingsMainPage from "../views/settings/Main.vue";
import SettingsCategory from "../views/settings/Categories.vue";
import SettingsBusinessProfile from "../views/settings/ProfileView.vue";
import SettingsUserProfile from "../views/settings/UserProfileView.vue";
import SettingsUsers from "../views/settings/Users.vue";
import SettingsUser from "../views/settings/User.vue";
import SettingsApprovals from "../views/settings/ApprovalsHome.vue";
import SettingsApprovalsPending from "../views/settings/ApprovalsPending.vue";
import SettingsRoles from "../views/settings/RolesOverview.vue";
import SettingsRole from "../views/settings/RoleEdit.vue";
import SettingsNotification from "../views/settings/NotificationsHome.vue"
import SettingsBilling from "../views/settings/BillingHome.vue"
import SettingsAudit from "../views/settings/AuditLog.vue"
import SettingsDocumentFormat from "../views/settings/DocumentFormat.vue"
import SettingsDevicManagement from "../views/settings/DeviceManagement.vue"
import HomePurchase from "../views/purchase/HomePurchases.vue";
import AddPurchase from "../views/purchase/AddPurchase.vue";
import PdfPurchase from "../views/purchase/PdfPurchase.vue";
import Orders from "../views/order/HomeOrders.vue";
import CancelledOrders from "../views/order/CancelledOrders.vue";
import AddPickup from "../views/order/pickup/AddPickup.vue";
import ProcessPickup from "../views/order/pickup/ProcessPickup.vue";
import PickupPdf from "../views/order/pickup/PickupPdf.vue";
import GatePickup from "../views/order/pickup/GatePickup.vue";
import AddDispatch from "../views/order/dispatch/AddDispatch.vue";
import ProcessDispatch from "../views/order/dispatch/ProcessDispatch.vue";
import PdfDispatch from "../views/order/dispatch/PdfDispatch.vue";
import GateDispatch from "../views/order/dispatch/GateDispatch.vue";
import ReportsHome from "../views/reports/ReportsHome.vue";
import CommingSoon from "../views/supplementary/CommingSoon.vue"
import Departments from "@/views/settings/Departments.vue";
import ProcessedOrders from "@/views/order/ProcessedOrders.vue";
import DeliveryPickup from "@/views/order/pickup/DeliveryPickup.vue";
import DeliveryDispatch from "@/views/order/dispatch/DeliveryDispatch.vue";

// const permission = JSON.parse(localStorage.getItem('permission'))

const routes = [
    {
        path: "/",
        name: "Login",
        component: Login,
        meta: {
            title: 'Pavicon Inventory'
        }
    },
    {
        path: "/home",
        name: "Home",
        component: Home,
        meta: {
            title: 'Pavicon Inventory',
            permission: 'dashboard'
            
        }
    },
    {
        path: "/inventory/products",
        name: "ViewProducts",
        component: ViewProducts,
        meta: {
            title: 'Pavicon Inventory',
            keepAlive: false
        }
    },
    {
        path: "/inventory/products/add",
        name: "AddProduct",
        component: AddProduct,
        meta: {
            title: 'Pavicon Inventory',
            permission: 'productsManage'
        }
    },
    {
        path: "/inventory/products/:id",
        params:"id",
        name: "ViewProduct",
        component: ViewProduct,
        meta: {
            title: 'Pavicon Inventory',
            permission: 'productsManage'
        }
    },
    {
        path: "/inventory/products/edit/:id",
        params:"id",
        name: "EditProduct",
        component: EditProduct,
        meta: {
            title: 'Pavicon Inventory',
            permission: 'productsManage'
        }
    },
    {
        path: "/inventory/stocks",
        name: "ManageStocks",
        component: ManageStocks,
        meta: {
            title: 'Pavicon Inventory',
            keepAlive: false
        }
    },
    {
        path: "/inventory/stocks/adjustments",
        name: "StockAdjustments",
        component: StockAdjustments,
        meta: {
            title: 'Pavicon Inventory'
        }  
    },
    {
        path: "/inventory/stocks/adjustments/:id",
        params:"id",
        name: "StockAdjustment",
        component: StockAdjustment,
        meta: {
            title: 'Pavicon Inventory'
        }
    },
    {
        path: "/inventory/stocks/transfers",
        name: "StockTransfers",
        component: StockTransfers,
        meta: {
            title: 'Pavicon Inventory'
        }
    },
    {
        path: "/inventory/stocks/transfers/:id",
        params:"id",
        name: "StockTransfer",
        component: StockTransfer,
        meta: {
            title: 'Pavicon Inventory'
        }
    },
    {
        path: "/inventory/stocks/reserveds",
        name: "StockReserveds",
        component: StockReserveds,
        meta: {
            title: 'Pavicon Inventory'
        }
    },
    {
        path: "/inventory/stocks/reserveds/:id",
        params:"id",
        name: "StockReservedActivity",
        component: StockReservedActivity,
        meta: {
            title: 'Pavicon Inventory'
        }
    },
    {
        path: "/inventory/stocks/reserved/:id",
        params:"id",
        name: "StockReserved",
        component: StockReserved,
        meta: {
            title: 'Pavicon Inventory'
        }
    },
    {
        path: "/inventory/stocks/markedreturn",
        name: "MarkedReturn",
        component: MarkedReturn,
        meta: {
            title: 'Pavicon Inventory'
        }
        
    },
    {
        path: "/transaction",
        name: "Transaction",
        component: Transaction,
        meta: {
            title: 'Pavicon Inventory',
            permission: 'transaction'
        }
    },
    {
        path: "/transaction/addbatch",
        name: "AddBatch",
        component: AddBatch,
        meta: {
            title: 'Pavicon Inventory',
            permission: 'batchAdd'
        }
    },
    {
        path: "/transaction/savedbatch/:id",
        params:"id",
        name: "SavedBatch",
        component: SavedBatch,
        meta: {
            title: 'Pavicon Inventory',
            permission: 'transaction'
        }
    },
    {
        path: "/transaction/processedbatch/:id",
        params:"id",
        name: "ProcessedBatch",
        component: ProcessedBatch,
        meta: {
            title: 'Pavicon Inventory',
            permission: 'batchVerify'
        }
    },
    {
        path: "/transaction/pdfbatch/:id",
        params:"id",
        name: "PdfBatch",
        component: PdfBatch,
        meta: {
            title: 'Pavicon Inventory',
            permission: 'transaction'
        }
    },
    {
        path: "/transaction/incompletenotes",
        name: "IncompleteDelivery",
        component: IncompleteDelivery,
        meta: {
            title: 'Pavicon Inventory',
            permission: 'transaction'
        }
    },
    {
        path: "/transaction/incompletenotes/add",
        name: "AddIncompleteDelivery",
        component: AddIncompleteDelivery,
        meta: {
            title: 'Pavicon Inventory',
            permission: 'transaction'
        }
    },
    {
        path: "/transaction/incompletenotes/pdf/:id",
        params:"id",
        name: "PdfIncompleteDelivery",
        component: PdfIncompleteDelivery,
        meta: {
            title: 'Pavicon Inventory',
            permission: 'transaction'
        }
    },
    {
        path: "/suppliers",
        name: "Suppliers",
        component: Suppliers,
        meta: {
            title: 'Pavicon Inventory',
            permission: 'supplierView'

        }
    },
    {
        path: "/suppliers/add",
        name: "AddSupplier",
        component: AddSupplier,
        meta: {
            title: 'Pavicon Inventory',
            permission: 'supplierAdd'
        }
    },
    {
        path: "/suppliers/:id",
        params:"id",
        name: "ViewSupplier",
        component: ViewSupplier,
        meta: {
            title: 'Pavicon Inventory',
            permission: 'supplierView'
        }
        
    },
    {
        path: "/orders/process",
        name: "Orders",
        component: Orders,
        meta: {
            title: 'Pavicon Inventory'
        }
    },
    {
        path: "/orders",
        name: "ProcessOrders",
        component: ProcessedOrders,
        meta: {
            title: 'Pavicon Inventory'
        }
    },
    {
        path: "/orders/cancelled",
        name: "CancelledOrders",
        component: CancelledOrders,
        meta: {
            title: 'Pavicon Inventory'
        }
    },
    {
        path: "/orders/pickup/add",
        name: "AddPickup",
        component: AddPickup,
        meta: {
            title: 'Pavicon Inventory',
            permission: 'pickupAdd'
        }
    },
    {
        path: "/orders/pickup/process/:id",
        params:"id",
        name: "ProcessPickup",
        component: ProcessPickup,
        meta: {
            title: 'Pavicon Inventory',
            permission: 'pickupProcess'
        }
    },
    {
        path: "/orders/pickup/pdf/:id",
        params:"id",
        name: "PickupPdf",
        component: PickupPdf,
        meta: {
            title: 'Pavicon Inventory'
        }
    },
    {
        path: "/orders/pickup/gate/:id",
        params:"id",
        name: "GatePickup",
        component: GatePickup,
        meta: {
            title: 'Pavicon Inventory'
        }
    },
    {
        path: "/orders/pickup/delivery/:id",
        params:"id",
        name: "Pickup Delivery",
        component: DeliveryPickup,
        meta: {
            title: 'Pavicon Inventory'
        }
    },
    {
        path: "/orders/dispatch/delivery/:id",
        params:"id",
        name: "Dispatch Delivery",
        component: DeliveryDispatch,
        meta: {
            title: 'Pavicon Inventory'
        }
    },
    {
        path: "/orders/dispatch/add",
        name: "AddDispatch",
        component: AddDispatch,
        meta: {
            title: 'Pavicon Inventory',
            permission: 'dispatchAdd'
        }
    },
    {
        path: "/orders/dispatch/process/:id",
        params:"id",
        name: "ProcessDispatch",
        component: ProcessDispatch,
        meta: {
            title: 'Pavicon Inventory',
            permission: 'dispatchProcess'
        }
    },
    {
        path: "/orders/dispatch/pdf/:id",
        params:"id",
        name: "PdfDispatch",
        component: PdfDispatch,
        meta: {
            title: 'Pavicon Inventory'
        }
    },
    {
        path: "/orders/dispatch/gate/:id",
        params:"id",
        name: "GateDispatch",
        component: GateDispatch,
        meta: {
            title: 'Pavicon Inventory'
        }
    },
    {
        path: "/warehouses",
        name: "Warehouses",
        component: Warehouses,
        meta: {
            title: 'Pavicon Inventory'
        }
    },
    {
        path: "/warehouses/add",
        name: "AddWarehouse",
        component: AddWarehouse,
        meta: {
            title: 'Pavicon Inventory',
            permission: 'warehouseAdd'
        }
    },
    {
        path: "/warehouses/virtual",
        name: "VirtualWarehouse",
        component: VirtualWarehouse,
        meta: {
            title: 'Pavicon Inventory'
        }
    },
    {
        path: "/warehouses/locate",
        name: "LocateWarehouse",
        component: LocateWarehouse,
        meta: {
            title: 'Pavicon Inventory'
        }
    },
    {
        path: "/warehouses/activity/:id",
        params:"id",
        name: "ProductActivity",
        component: ProductActivity,
        meta: {
            title: 'Pavicon Inventory'
        }
    },
    {
        path: "/warehouses/view/:id",
        params:"id",
        name: "ViewWarehouse",
        component: ViewWarehouse,
        meta: {
        title: 'Pavicon Inventory'
        }
    },
    {
        path: "/warehouses/product/:id/:type/:title",
        props: true,
        name: "ProductWarehouse",
        component: ProductWarehouse,
        meta: {
            title: 'Pavicon Inventory'
        }
    },
    {
        path: "/purchases",
        name: "Purchases",
        component: HomePurchase,
        meta: {
            title: 'Pavicon Inventory'
        }
    },
    {
        path: "/comming-soon",
        name: "Comming Soon",
        component: CommingSoon,
        meta: {
            title: 'Pavicon Inventory'
        }
    },
    {
        path: "/purchases/add",
        name: "AddPurchase",
        component: AddPurchase,
        meta: {
            title: 'Pavicon Inventory',
            permission: 'purchaseAdd'
        }
    },
    {
        path: "/purchases/pdf/:id",
        params:"id",
        name: "PdfPurchase",
        component: PdfPurchase,
        meta: {
            title: 'Pavicon Inventory'
        }
    },
    {
        path: "/settings",
        name: "SettingsMain",
        component: SettingsMainPage,
        meta: {
            title: 'Pavicon Inventory'
        }
    },
    {
        path: "/settings/categories",
        name: "SettingsCategory",
        component: SettingsCategory,
        meta:{ 
            title: 'Pavicon Inventory',
        }
    },
    {
        path: "/settings/audit",
        name: "SettingsAudit",
        component: SettingsAudit,
        meta:{ title: 'Pavicon Inventory'}
    },
    {
        path: "/settings/billing",
        name: "SettingsBilling",
        component: SettingsBilling,
        meta:{ title: 'Pavicon Inventory'}
    },
    {
        path: "/settings/document-format",
        name: "SettingsDocumentFormat",
        component: SettingsDocumentFormat,
        meta:{ title: 'Pavicon Inventory'}
    },
    {
        path: "/settings/device-management",
        name: "SettingsDeviceManagement",
        component: SettingsDevicManagement,
        meta:{ title: 'Pavicon Inventory'}
    },
    
    {
        path: "/settings/notifications",
        name: "SettingsNotification",
        component: SettingsNotification,
        meta:{ title: 'Pavicon Inventory'}
    },
    {
        path: "/settings/business-profile",
        name: "SettingsBusinessDetail",
        component: SettingsBusinessProfile,
        meta:{ title: 'Pavicon Inventory'}
    },
    {
        path: "/settings/profile",
        name: "SettingsProfile",
        component: SettingsUserProfile,
        meta:{ 
            title: 'Pavicon Inventory'
        }
    },
    {
        path: "/settings/users",
        name: "SettingsUsers",
        component: SettingsUsers,
        meta:{ 
            title: 'Pavicon Inventory',
            permission: 'usersView'
        }
    },
    {
        path: "/settings/users/:id",
        params:"id",
        name: "ViewUser",
        component: SettingsUser,
        meta:{ 
            title: 'Pavicon Inventory',
            permission: 'usersManage'
        }
    },
    {
        path: "/settings/approvals",
        name: "SettingsApprovals",
        component: SettingsApprovals,
        meta:{ 
            title: 'Pavicon Inventory',
            permission: 'approveTransfer'
        }
    },
    {
        path: "/settings/departments",
        name: "SettingsDepartments",
        component: Departments,
        meta:{ 
            title: 'Pavicon Inventory',
            permission: 'approveTransfer'
        }
    },
    {
        path: "/settings/approvals/pending",
        name: "SettingsApprovalsPending",
        component: SettingsApprovalsPending,
        meta:{ title: 'Pavicon Inventory'}
    },
    {
        path: "/settings/roles",
        name: "SettingsRoles",
        component: SettingsRoles,
        meta:{ title: 'Pavicon Inventory'}
    },
    {
        path: "/settings/roles/:id",
        params:"id",
        name: "SettingsRole",
        component: SettingsRole,
        meta:{ title: 'Pavicon Inventory'}
    },
    {
        path: "/reports",
        name: "ReportsHome",
        component: ReportsHome,
        meta:{ title: 'Pavicon Inventory'}
    },

];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((toRoute, fromRoute, next) => {
    /*const requiredPermission = toRoute.meta.permission;
    if(requiredPermission?.length > 0)
        if (permission[requiredPermission] == false) {
            alert('You do not have permission to access this page.');
            return next('/'); 
        }*/
    window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : 'Home';
    next();
})

export default router;