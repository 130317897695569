<template>
    <div>
    <!--
        <div v-if="isVisible" style="position: absolute; width: 100%; height: 2000px; z-index:1;background-color: #f2f2f2;">
            <h3 style="margin-top: 100px; text-align: center;padding: 40px;">Wait as the PDF is being generated</h3>
            <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
    -->

    

    <PdfModal @close="closeModal">
        <template v-slot:header>
        <h5 class="fw-bold">PDF Preview</h5>
        </template>
    
        <template v-slot:body>
            <div class="pdf" id="pdfStart" ref="pdfStart" style="margin: 5px;">
                <div class="row">
                    <div class="col-md-7">
                        <h4>Batch Processing Note <button v-if="batch.Purchase" class="btn btn-default" style="margin-left: 15px; border-radius: 0px !important; background-color: #f2f2f2; font-size: 12px">Purchase Order</button></h4>
                    </div>
                    <div class="col-md-5">
                        <p style="float:right; border: 1px solid #000;">
                            <span style="background-color:#C1D4EB; color: #000;font-size: 17px; padding-bottom: 2px; padding-left: 2px; padding-right: 2px; font-weight: bold;">BPN Status</span>
                            <span style="color: #000; margin-left: 5px; margin-right: 5px"> {{ batch.status }}</span> </p>
                    </div>
        
                    <div class="col-md-12">
                        <h4 class="text-15">{{ batch.batchNo }} <span v-if="batch?.mergeId" style="font-size: 21px; font-weight: bold;">Merged With {{ mergedBatch?.batchNo }}</span></h4>
                    </div>
                    
                    <div class="col-md-7">
                        <div style="border: 1px solid #000; width: 400px;">
                            <p style="background-color:#C1D4EB;padding: 10px; margin:0px; font-weight: bold !important;">Delivery/Courier Details From</p>
                            <p v-if="batch.isInternal != true" style="margin: 1px 10px; font-weight: bold;">{{ batch.Supplier?.name }}</p>
                            <p v-if="batch.isInternal != true" style="margin: 1px 10px;">{{ batch.supplierDriver }}</p>
                            <p v-if="batch.isInternal != true" style="margin: 1px 10px;">{{ batch.supplierContact }}</p>
                            <p v-if="batch.isInternal != true" style="margin: 1px 10px;">{{ batch.supplierVehicle }}</p>
                            <p v-if="batch.isInternal == true" style="margin: 1px 10px;">{{ driver?.name }}</p>
                            <p v-if="batch.isInternal == true" style="margin: 1px 10px;">{{ driver?.phone }}</p>
                        </div>
        
                    </div>
                    <div class="col-md-2">
                    </div>
        
                    <div class="col-md-3 qr">
                        <canvas ref="qrcodeCanvas"></canvas>
                    </div>
        
                    <div class="col-md-5">
                        <p style="font-size: 13px !important;">Note: This document must be presented upon request at the gate or any relevant person in the warehouse or HQ compound.</p>
                    </div>
        
                    <div class="col-md-7" id="companyDetails">
                        <h4 class="mt-2">{{ profile?.businessName }}</h4>
                        <h4 style="font-size:smaller !important;">{{ profile?.businessAddress }}</h4>
                    </div>
                </div>
        
                <div class="row" style="border: 1px solid #000; padding: 0px !important">
                    <div class="row" style="background-color:#C1D4EB; margin-left: 0px !important; margin-top: 0px !important">
                        <div class="col-md-6" style="padding: 10px; border-right: 1px solid #f2f2f2;">Stock Description</div>
                        <div class="col-md-6" style="padding: 10px;">Consignment Details</div>
                    </div>
                    <div class="row" style="margin-top: 0px !important; padding-right: 0px !important; width:100% !important; margin-right:0px !important">
                        <div class="col-md-2" style="border-bottom: 1px solid #808080; border-right: 1px solid #808080;padding: 5px;">Stock Category</div>
                        <div class="col-md-2" style="border-bottom: 1px solid #808080; border-right: 1px solid #808080;padding: 5px;">BPN Tags</div>
                        <div class="col-md-2" style="border-bottom: 1px solid #808080; border-right: 1px solid #808080;padding: 5px;">Project</div>
                        <div class="col-md-2" style="border-bottom: 1px solid #808080; border-right: 1px solid #808080;padding: 5px;">Purchase Order No.</div>
                        <div class="col-md-2" style="border-bottom: 1px solid #808080; border-right: 1px solid #808080;padding: 5px;">Reference 1 No.</div>
                        <div class="col-md-2" style="border-bottom: 1px solid #808080; padding: 5px;">Reference 2 No.</div>
                        <div class="col-md-2" style="border-right: 1px solid #808080;padding: 5px;">{{  batch.Nog?.nog }}</div>
                        <div class="col-md-2" style="border-right: 1px solid #808080;padding: 5px;">{{  batch.Tag?.tag }}</div>
                        <div class="col-md-2" style="border-right: 1px solid #808080;padding: 5px;">{{  batch.project }}</div>
                        <div class="col-md-2" style="border-right: 1px solid #808080;padding: 5px;" v-if="batch.Purchase">{{  batch.Purchase?.purchaseNo }}</div>
                        <div class="col-md-2" style="border-right: 1px solid #808080;padding: 5px;" v-else>N/A</div>
                        <div class="col-md-2" style="border-right: 1px solid #808080;padding: 5px;">{{  batch.reference1 }}</div>
                        <div class="col-md-2" style="padding: 5px;">{{  batch.reference2 }}</div>
                    </div>
                </div>
        
                <div class="row" style="border: 1px solid #000;">
                    <p style="background-color:#C1D4EB;padding: 10px; margin:0px; font-weight: bold !important;">Stock Data Input Details</p>
                    <div class="row" style="margin-top: 0px !important; padding-right: 0px !important;">
                        <div class="col-md-2" style="border-bottom: 1px solid #808080; border-right: 1px solid #808080;padding: 5px;">Data Entry by:</div>
                        <div class="col-md-2" style="border-bottom: 1px solid #808080; border-right: 1px solid #808080;padding: 5px;">Date & Time.</div>
                        <div class="col-md-2" style="border-bottom: 1px solid #808080; border-right: 1px solid #808080;padding: 5px;">Stock Verified By:</div>
                        <div class="col-md-2" style="border-bottom: 1px solid #808080; border-right: 1px solid #808080;padding: 5px;">Guardian</div>
                        <div class="col-md-2" style="border-bottom: 1px solid #808080; border-right: 1px solid #808080;padding: 5px;">Split Receipt</div>
                        <div class="col-md-2" style="border-bottom: 1px solid #808080; padding: 5px;">IDN</div>
        
                        <div class="col-md-2" style="border-right: 1px solid #808080;padding: 5px;">{{  batch.User?.name }}</div>
                        <div class="col-md-2" style="border-right: 1px solid #808080;padding: 5px;">{{  formatDate(batch.createdAt,"true") }}</div>
                        <div class="col-md-2" style="border-right: 1px solid #808080;padding: 5px;" v-if="verifiedBy">{{ verifiedBy?.name }}</div>
                        <div class="col-md-2" style="border-right: 1px solid #808080;padding: 5px;" v-else>-</div>
                        <div class="col-md-2" style="border-right: 1px solid #808080;padding: 5px;" v-if="guardian">{{ guardian?.name }}</div>
                        <div class="col-md-2" style="border-right: 1px solid #808080;padding: 5px;" v-else>-</div>
                        <div class="col-md-2" style="border-right: 1px solid #808080;padding: 5px;" v-if="batch.isSplit == true">YES</div>
                        <div class="col-md-2" style="border-right: 1px solid #808080;padding: 5px;" v-else>NO</div>
                        <div class="col-md-2" style="padding: 5px;" v-if="batch.IncompleteDeliveryNote">{{ batch.IncompleteDeliveryNote?.idnNo }}</div>
                        <div class="col-md-2" style="padding: 5px;" v-else>N/A</div>
                    </div>
                </div>
        
                <div class="row" style="border: 1px solid #000;">
                    <p style="background-color:#C1D4EB;padding: 10px; margin:0px; font-weight: bold !important;">Stock Description</p>
                    <table class="table table-bordered">
                        <tbody v-if="batch.items?.length > 0">
                            <tr>
                                <th>No.</th>
                                <th>Product</th>
                                <th>Unit</th>
                                <th v-if="batch.Purchase">LPO Qty</th>
                                <th>Received Qty</th>
                                <th>Price</th>
                                <th>Condition</th>
                                <th>Warehouse</th>
                                <th>Comments</th>
                            </tr>
                            <tr v-for="(item, index) in batch?.items" v-bind:key="item.id">
                                <td>{{ (index+1) }}</td>
                                <td>{{ item?.Product?.name }}</td>
                                <td>{{ item?.Product.unit?.unit }}</td>
                                <td v-if="batch.Purchase">{{ getLpoQty(item?.Product?.id) }}</td>
                                <td>{{ item?.quantity }}</td>
                                <td>{{ item?.price }}</td>
                                <td>{{ item?.condition }}</td>
                                <td>{{ item?.Warehouse?.name }}</td>
                                <td>{{ item?.comment }}</td>
                            </tr>
                        </tbody>
                        <div v-else>
                            <label for="" class="alert alert-danger" style="margin: 10px auto; border-radius: 0px !important;  display: block;">No items found</label>
                        </div>
                    </table>
                </div>
                <div class="row" style="border: 1px solid #000;">
                    <p style="background-color:#C1D4EB;padding: 10px; margin:0px; font-weight: bold !important;">BPN Comments</p>
                    <p style="margin-left: 0px;">{{ batch?.confirmNote }}</p>
                </div>
                <div class="row" style="border: 1px solid #000;" v-if="batch?.mergeId">
                    <p style="background-color:#C1D4EB;padding: 10px; margin:0px; font-weight: bold !important;">BPN Merge Comments</p>
                    <p style="margin-left: 0px;" v-if="mergedBatch.mergeComment">{{ mergedBatch?.mergeComment }}</p>
                    <p style="margin-left: 0px;" v-else>{{ batch?.mergeComment }}</p>
                </div>
                <div class="row" id="footer">
                    <div class="col-md-5">
                        <p><span style="font-weight: bold;">Note:</span> Printed document is uncontrolled</p>
                    </div>
                    <div class="col-md-4">
                        <p><span style="font-weight: bold;">Printed On:</span> {{ formatDate(new Date(),"true") }}</p>
                    </div>
                    <div class="col-md-3">
                        <p><span style="font-weight: bold;">By:</span> {{ user.name }}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <img v-if="batch.User?.signature" :src="`${urlServer}/files/${batch.User?.signature}`" alt="" style="max-height: 100px !important; width: auto !important;"/>
                        <p style="margin-bottom: 0px; font-weight: bold !important; font-size: 18px !important;">Data Entry By:</p>
                        <p style="margin-bottom: 0px;"><span style="font-weight: bold;">Name:</span> {{ batch.User?.name }}</p>
                        <p style="margin-bottom: 0px;"><span style="font-weight: bold;">System ID:</span> {{ batch.User?.id }}</p>
                        <p style="margin-bottom: 0px;"><span style="font-weight: bold;">Date:</span> {{ formatDate(batch.createdAt,"true") }}</p>
                    </div>
                    <div class="col-md-6" v-if="verifiedBy">
                        <img v-if="verifiedBy?.signature" :src="`${urlServer}/files/${verifiedBy?.signature}`" alt="" style="max-height: 100px !important; width: auto !important;"/>
                        <p style="margin-bottom: 0px; font-weight: bold !important; font-size: 18px !important;">Stock Verified By:</p>
                        <p style="margin-bottom: 0px;"><span style="font-weight: bold;">Name:</span> {{ verifiedBy?.name }}</p>
                        <p style="margin-bottom: 0px;"><span style="font-weight: bold;">System ID:</span> {{ verifiedBy?.id }}</p>
                        <p style="margin-bottom: 0px;"><span style="font-weight: bold;">Date:</span> {{ formatDate(batch.updatedAt,"true") }}</p>
                    </div>
                </div>
                <div style="margin-top: 90px !important;">
                   <!--<img  v-if="profile?.systemName" :src="`${urlServer}/files/${profile?.systemName}`" style="width: 150px;"/>-->
                    <img  v-if="profile?.businessLogo" :src="profile?.businessLogo" style="height: 60px; width: 250px;"/> 
    
                </div>
            </div>
        </template>
    
        <template v-slot:footer>
            <button class="btn-style small" style="border: 1px solid black; float: right;" @click="downloadPDF">Download Pdf</button>
            <button class="btn-style small" style="border: 1px solid black; float: right;" @click="copyToClipboard">Copy Pdf Link</button>
            <button class="btn btn-default" style="border: 1px solid black; float: right; margin-right: 20px;" @click="$router.back()">Cancel</button>
        </template>
    </PdfModal>



   
</div>
  </template>
  
<script>
import Swal from "sweetalert2";
import axios from "axios"
import QRCode from 'qrcode-generator';
import PdfModal from "@/components/PdfModal.vue";
import html2pdf from "html2pdf.js";
import { mapGetters, mapActions } from 'vuex'

export default {
    name: 'BatchPdf',
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        urlServer:process.env.VUE_APP_URL,
        batchId: "",
        batch:"",
        verifiedBy:"",
        guardian:"",
        driver:"",
        mergedBatch:"",
        user:"",
        updateCount:1,
        spinnerColor:'#ff1d5e',
        pdfLink: "",
        imgData: ""

    }),

    components: {
        PdfModal
    },

    mounted(){
        this.batchId = this.$route.params.id
        this.setupBatch(this.batchId)
        this.setupUser()
       // this.generateQRCode()
        this.fetchProfile()
       
    },
    computed:{
        ... mapGetters (["getProfile"]),
        profile() { return this.getProfile }
    },
    methods: {
        ... mapActions(['fetchProfile']),
        async exportToPDF() {
            this.generatePdf()
            this.$router.back()
        },

        downloadPDF() {
            html2pdf(document.getElementById("pdfStart"), { 
                margin: 0.2, 
                filename: new Date().getTime()+"_batch_note.pdf", 
                image:{ type: 'jpeg', quality: 0.98 },
                html2canvas:{ scale: 2, useCORS: true },
                jsPDF:{ unit: 'in',format: 'a4', orientation: 'landscape' }
            });
        },

        async generatePdf(){
            try{
                Swal.fire({
                    html: "Generating pdf",
                    timer: 2000,
                    timerProgressBar: true,
                    didOpen: () => {
                        Swal.showLoading();
                    }
                    }).then((result) => {
                    if (result.dismiss === Swal.DismissReason.timer) {
                        console.log("I was closed by the timer");
                    }
                    });
                const response = await axios.get(`${process.env.VUE_APP_URL}/pdf/batch/${this.batchId}`)
                console.log(response)
            }catch(err){
                console.log(err)
            }finally{
                console.log("finally")
            } 
        },

        closeModal(){
            this.$router.back()
        },

        removeQuotes(str) {
            if (str.startsWith('"') && str.endsWith('"') && str.length >= 2) {
                return str.slice(1, -1);
            } else {
                return str;
            }
        },
        
        generateQRCode(link) {
            const qr = QRCode(0, 'M');
            qr.addData(link);
            qr.make();
            const canvas = this.$refs.qrcodeCanvas;
            const ctx = canvas.getContext('2d');
            const cellSize = 5;

            canvas.width = qr.getModuleCount() * cellSize;
            canvas.height = qr.getModuleCount() * cellSize;

            for (let row = 0; row < qr.getModuleCount(); row++) {
                for (let col = 0; col < qr.getModuleCount(); col++) {
                    ctx.fillStyle = qr.isDark(row, col) ? "#000000" : "#ffffff";
                    ctx.fillRect(col * cellSize, row * cellSize, cellSize, cellSize);
                }
            }
        },

      

        formatBoolean(value){
            if(value){
            return "YES"
            } else{
            return "NO"
            }
        },

        getLpoQty(itemId){
            let obj = this.batch?.Purchase?.PurchaseProduct.find(i => i.productId === itemId);
            return obj?.quantity
        },

        async setupBatch(itemId){
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json",
                    'Authorization': 'Bearer '+this.token
                 },
            };
            const res = await fetch(process.env.VUE_APP_URL+'/batches/'+itemId, requestOptions)
            const data = await res.json()
            if(data.status == true){
                this.batch = data.batch;
                this.verifiedBy = data.verifiedBy;
                this.guardian = data.guardian;
                this.driver = data.driver;
                this.mergedBatch = data.mergedBatch;
                this.pdfLink = `${process.env.VUE_APP_URL}/pdf/batch/${this.batchId}`
                this.generateQRCode(this.pdfLink)
                //this.pdfLink = this.shortenUrl(`${process.env.VUE_FRONT_URL}/transaction/pdfbatch/${this.batchId}`)
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Batch Processing Note not found. Try again',
                    text: data.message
                })
            }
        },


        async copyToClipboard(){
            try {
                await navigator.clipboard.writeText(this.pdfLink);
                alert('Pdf link copied')
            } catch (err) {
                console.error('Failed to copy text to clipboard', err);
            }

        },

        async setupUser(){
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token},
            };
            const res = await fetch(process.env.VUE_APP_URL+'/users/profile', requestOptions)
            const data = await res.json()
            if(data.status == true){
                this.user = data.user;
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Batch Note not found. Try again',
                    text: data.message
                })
            }
        },

    }
}
</script>

<style scoped>
p, span, h3, h4{
    color: #000 !important;
}
h3{
    font-size: 33px !important;
}
h4{
    font-weight: bold !important;
}
#companyDetails h4{
    text-align: right;
    font-size: 19px;
}
#footer p{
    font-size: 12px !important;
}
.row{
    margin-top: 20px;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 150px;
  height: 150px;
  margin: auto;
  display: block;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #002060;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>
