<template>
    <div class="inventory">
  
      <Header :mainHeader="true"/>
  
      <section id="suppliers-add">
        <div class="container">
          <h3>Manage Order - Process Dispatch Note - {{ dispatch.dispatchNo }}
          <button v-if="dispatch.isImmediate == true" class="btn btn-danger" style="border-radius: 0px !important">Urgent</button>
            </h3>
          <div style="margin-top:30px;">
            <div class="form">
              <div class="row">
                <strong>1. Requesters Details</strong>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="address">Name</label>
                    <input type="text" readonly id="address" class="form-control" :value="dispatch.User?.name">
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="address">Position</label>
                    <input type="text" readonly id="address" class="form-control" :value="dispatch.User?.designation">
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="address">Date Requested</label>
                    <input type="text" readonly id="address" class="form-control" :value="formatDate(dispatch.createdAt,'true')" >
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="address">Purpose</label>
                    <input type="text" readonly id="address" class="form-control" :value="dispatch.Purpose?.purpose">
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="address">Dept/Project/Site</label>
                    <input type="text" readonly id="address" class="form-control" :value="dispatch.project">
                  </div>
                </div>
              </div>
            </div>

            <div class="form">
              <div class="row mt-3">
                <strong>2. Dispatch Instructions</strong>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="address">Warehouse</label>
                    <input type="text" readonly id="address" class="form-control" :value="dispatch.Warehouse?.name">
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="address">Assignee Name</label>
                    <input type="text" readonly id="address" class="form-control" :value="assignedTo?.name">
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="address">Instructions/Comments</label>
                    <input type="text" readonly id="address" class="form-control" :value="dispatch.instructions">
                  </div>
                </div>
              </div>
            </div>


            <div class="form">
              <div class="row mt-3 mb-3">
                <strong>3. Dispatch</strong>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="address">Dispatched To</label>
                    <select v-model="dispatchToId" class="full" id="users"  v-if="users.length > 0">
                        <option v-for="user in users" v-bind:Key="user.id" :value="user.id">
                            {{ user.name }}
                        </option>
                    </select>
                    <div v-else>
                        <h5 for="" style="color: red !important;">No User Found.</h5> 
                        <button class="btn btn-primary btn-sm" @click="this.fetchUsers">Fetch Users</button>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="address">Vehicle Reg No.</label>
                    <input type="text" id="address" class="form-control" v-model="vehicle">
                  </div>
                </div>
                <div class="col-md-7">
                  <div class="form-group">
                    <label for="address">Dispatch Comments</label>
                    <input type="text" id="address" class="form-control" v-model="comments">
                  </div>
                </div>
              </div>
            </div>


            <strong class="mt-3">4. Stock Items to Dispatch</strong>
            <table class="table table-hover">
                <thead>
                    <th>No. </th>  
                    <th>Product Description</th>
                    <th>Product Code</th>
                    <th>Unit</th>
                    <th>Qty</th>
                    <th>Qty Avail</th>
                    <th>Condition</th>
                    <th>Qty Dispatched</th>
                    <th>Comment</th>
                    <th>Status</th>
                    <th>Action</th>
                </thead>
                <tbody v-if="dispatch.items?.length > 0">
                    <tr v-for="(item, index) in dispatch.items" v-bind:key="item.id">
                        <td>{{ (index+1) }}</td>
                        <td>{{ item.Product?.name }}</td>
                        <td>{{ item.Product?.code }}</td>
                        <td>{{ item.Product?.unit?.unit }}</td>
                        <td>{{ formatQty(item.quantity) }}</td>
                        <td>{{ item.quantityAvailable }}</td>
                        <td>{{ item.condition }}</td>
                        <td><input type="number" min="0" :max="item.quantity" class="form-control" :id="'assigned'+item.id" :value="item.quantityDispatched" /></td>
                        <td><input type="text" class="form-control" :id="'comment'+item.id" :value="item.collectComment" /></td>
                        <td>
                          <button v-if="item.status == 'completed'" class="btn btn-sm btn-success">Completed</button>
                        </td>
                        <td class="padding-top: 5px;">
                          <button v-if="item.isReturn == true" class="btn btn-default btn-sm" style="border: 1px solid #000; background-color: #f2f2f2;margin-right: 10px;">To Return</button>
                          <button v-else class="btn btn-default btn-sm" style="border: 1px solid #000;margin-right: 5px; " @click="returnItem(item.id)">Return</button>
                          <button v-if="item.status == 'completed'" class="btn btn-sm btn-success">Dispatched</button>
                          <button v-else class="btn btn-default btn-sm" style="border: 1px solid #000; margin-left: 10px;" @click="assignItem(item.id)">Dispatch</button>
                        </td>
                    </tr>
                </tbody>
                <div v-else>
                  <label for="" class="text-danger" style="margin: 10px auto;  display: block;">No items found</label>
                </div>
            </table>

            <div>
              <button class="btn-style small" style="border: 1px solid black; float: right;" @click="processDispatch">Process</button>
              <button class="btn btn-default" style="border: 1px solid black; float: right; margin-right: 10px;" @click="$router.push('/orders')">Close</button>
            </div>
  
          </div>
        </div>
      </section>
  
    </div>
  </template>
  
  <script>
import { mapGetters, mapActions } from 'vuex'
  import Swal from 'sweetalert2'
  import Header from '../../../components/Header';
  
  export default {
    name: 'ProcessDispatch',
    components: {
      Header,
    },
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        displayOrder: "none",
        dispatch:"",
        dispatchToId:"",
        vehicle:"",
        comments: "",
        items:[],
        dispatchNoteId: "",
        assignedTo:"",
    }),
    computed:{
        ... mapGetters (["getUsers"]),
        users () { return this.getUsers },
    },
    mounted(){
        this.fetchUsers()
        this.dispatchNoteId = this.$route.params.id
        this.setupDispatch(this.dispatchNoteId)
    },
    methods:{
        ... mapActions(['fetchUsers']),
      formatBoolean(value){
        if(value){
          return "YES"
        } else{
          return "NO"
        }
      },
      async setupDispatch(itemId){
        const requestOptions = {
              method: "GET",
              headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
          };
          const res = await fetch(process.env.VUE_APP_URL+'/dispatches/'+itemId, requestOptions)
          const data = await res.json()
          if(data.status == true){
              this.dispatch = data.dispatch;
              console.log(this.dispatch)
              this.assignedTo = data.assignedTo;
          }else{
            Swal.fire({
                icon: 'error',
                title: 'Dispatch Note not found. Try again',
                text: data.message
            })
          }
      },
      async returnItem(itemId){
        const requestOptions = {
              method: "POST",
              headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
              body: JSON.stringify({ 
                itemId,
                disptachNoteId:this.dispatchNoteId,
              })
          };
          const res = await fetch(process.env.VUE_APP_URL+'/dispatches/item/return', requestOptions)
          const data = await res.json()
          if(data.status == true){
              this.dispatch = data.dispatch;
          }else{
            Swal.fire({
                icon: 'error',
                title: 'Item not set to return. Try again',
                text: data.message
            })
          }
      },
      async assignItem(itemId){
        const qty = document.getElementById('assigned'+itemId).value
        const comment = document.getElementById('comment'+itemId).value
        const quantityRequested = document.getElementById('assigned'+itemId).max

        if(qty > quantityRequested){
          Swal.fire({
              icon: 'error',
              title: 'Quantity dispatched is more than requested',
              text: 'Cannot assign more quantity than requested'
          })
          return
        }

        if(qty <= 0 || qty.length < 1){
          Swal.fire({
              icon: 'error',
              title: 'Quantity dispatched is invalid',
              text: 'Cannot assign 0 items or less'
          })
          return
        }
        const requestOptions = {
              method: "POST",
              headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
              body: JSON.stringify({ 
                itemId,
                comment,
                qty,
                dispatchNoteId:this.dispatchNoteId,
              })
          };

          const res = await fetch(process.env.VUE_APP_URL+'/dispatches/item/assign', requestOptions)
          const data = await res.json()

          if(data.status == true){
              this.dispatch = data.dispatch;
          }else{
            Swal.fire({
                icon: 'error',
                title: 'Item not assigned. Try again',
                text: data.message
            })
          }
      },
      async processDispatch(){
        if(this.vehicle == ""){
          Swal.fire({
                icon: 'error',
                title: 'Dispatch not Processed',
                text: 'The Vehicle Reg No is required'
            })
          return false;
        }
        if(this.quantityDispatched < 0){
          Swal.fire({
                icon: 'error',
                title: 'Dispatch not Processed',
                text: 'Cannot dispatch 0 quantity'
            })
          return false;
        }
        const requestOptions = {
              method: "POST",
              headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
              body: JSON.stringify({ 
                vehicle: this.vehicle,
                comment: this.comments,
                dispatchToId: this.dispatchToId,
                dispatchNoteId:this.dispatchNoteId,
              })
          };
          const res = await fetch(process.env.VUE_APP_URL+'/dispatches/process', requestOptions)
          const data = await res.json()
          if(data.status == true){
              this.$router.push('/orders')
          }else{
            Swal.fire({
                icon: 'error',
                title: 'Dispatch Note not processed. Try again',
                text: data.message
            })
          }
      },
      },
  }
  </script>

<style lang="scss" scoped>
input[readonly]{
  background-color: #cfcece !important;
}
</style>