<template>
    <div class="inventory">
      <Header :mainHeader="true"/>
      <section id="warehouse-add">
        <div class="container">
          <h4 class="mb-3 fw-bold text-primary">Add Warehouse - {{ title }}</h4>
          <strong >1. Add Details</strong>
          <div class="mt-2">
            <div class="form">
              <div class="row">
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="product-name">Warehouse Name</label>
                    <input type="text" class="form-control" id="product-name" v-model="name">
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="item-code">Physical Location</label>
                    <input type="text" class="form-control" id="item-code" v-model="location">
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="item-code">Description</label>
                    <input type="text" class="form-control" id="item-code" v-model="description">
                  </div>
                </div>

              <div class="row">
                  <div class="col-md-2">
                    <div class="check-button">
                      <div class="form-group">
                          <label>Add Sections</label>
                          <div class="components-button">
                            <span class="switcher switcher-1">
                                <input type="checkbox" id="switcher-1" v-model="hasSections" disabled>
                                <label for="switcher-1"></label>
                            </span>
                          </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="check-button">
                    <div class="form-group">
                        <label>Add Bins</label>
                        <div class="components-button">
                    <span class="switcher switcher-1">
                        <input type="checkbox" id="switcher-1" v-model="hasBins">
                        <label for="switcher-1"></label>
                    </span>
                        </div>
                    </div>
                    </div>
                  </div>
              </div>
                
                <div class="col-md-1">
                    <button class="btn-style small" style="margin-top: 15px !important" @click="postWarehouse" :disabled="displaySections">Create</button>
                </div>
  
              </div>
  
            </div>
          </div>



          <div class="panel" v-if="displaySections">
            <strong>2. Add Sections</strong>
            <table class="table table-hover">
              <thead>
                <th>No.</th>
                <th>Section Name</th>
                <th>Section Description</th>
                <th>No. of Bins</th>
                <th>Action</th>
              </thead>
              <tbody>
                <tr>
                    <td>#</td>
                    <td><input type="text" placeholder="Enter name" class="form-control" v-model="sectionName"></td>
                    <td><input type="text" placeholder="Enter Description" class="form-control" v-model="sectionDescription"></td>
                    <td><input type="number" min="0" placeholder="No of Bins" class="form-control" v-model="binCount"></td>
                    <td><button class="btn-style small pull-right" style="border:1px solid black;" @click="postSection">Add</button></td>
                </tr>
                    <tr v-for="(section, index) in sections" v-bind:key="section.id">
                        <td>{{ index+1 }}</td>
                        <td>{{ section.name }}</td>
                        <td>{{ section.description }}</td>
                        <td>{{ section.binCount }}</td>
                        <td>
                            <button class="btn btn-default btn-sm" style="border:1px solid black;" @click="removeSection(section.id)">Remove</button>
                        </td>
                    </tr>
              </tbody>
            </table>
          </div>


        </div>
      </section>
      
      <div class="mt-3 mb-5">
        <button class="btn btn-default" style="float: right; margin-right: 65px; border: 1px solid #000;" @click="goBack">Close</button>
      </div>
   


    </div>
  </template>
  
  <script>
  import Swal from 'sweetalert2'
  import Header from '../../components/Header';

  export default {
    name: 'AddWarehousePage',
    components: {
      Header,
    },
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        title:"Create Warehouse",
        sections:[],
        sectionName:"",
        warehouseName:"",
        warehouseLocation:"",
        warehouseDescription:"",
        sectionDescription:"",
        warehouseId: "",
        displaySections: false,
        name: "",
        location: "",
        description: "",
        hasSections: true,
        hasBins: false,
        binCount: 0,
    }),


    mounted() {
      window.addEventListener('beforeunload', this.handlePageReload);
    },


    




    methods:{

        handlePageReload() {
          console.log('Page is reloading');
          // Perform actions when page reloads
        },

    
      

        goBack(){
          this.addDefaultWarehouseSection()
          this.$router.push('/warehouses')
        
        },

        addDefaultWarehouseSection(){
           if(this.sections.length == 0 && this.warehouseId != ""){
            this.sectionName = "General Section"
            this.sectionDescription = "General Storage" 
          }
          this.postSection()
        },
       

        async postWarehouse () {
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
                body: JSON.stringify({ 
                    name: this.name,
                    location: this.location,
                    description: this.description,
                    hasSections: this.hasSections,
                    hasBins: this.hasBins,
                })
            };
            const res = await fetch(process.env.VUE_APP_URL+'/warehouses', requestOptions)
            const data = await res.json()
            if(data.status == true){
                this.warehouseId = data.warehouse.id
                this.displaySections = true
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Warehouse not created',
                    text: data.message
                })
            }
        },
        async updateWarehouse () {
            const requestOptions = {
                method: "PUT",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
                body: JSON.stringify({ 
                    name: this.name,
                    location: this.location,
                    description: this.description
                })
            };
            const res = await fetch(process.env.VUE_APP_URL+'/warehouses/'+this.warehouseId, requestOptions)
            const data = await res.json()
            if(data.status == true){
                this.warehouseId = data.warehouse.id
                this.name = data.warehouse.name
                this.location = data.warehouse.location
                this.description = data.warehouse.description
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Warehouse not updated',
                    text: data.message
                })
            }
        },
        async postSection () {
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
                body: JSON.stringify({ 
                    name: this.sectionName,
                    description: this.sectionDescription,
                    location: "",
                    sectionId: this.warehouseId,
                    binCount: this.binCount,
                    hasSections: false,
                    hasBins: false
                })
            };
            const res = await fetch(process.env.VUE_APP_URL+'/warehouses', requestOptions)
            const data = await res.json()
            console.log("Data", data)
            if(data.status == true){
              this.sectionDescription = "";
              this.sectionName = "";
              this.binCount = 0;
                this.sections= data.warehouse.sections
                Swal.fire({
                    icon: 'success',
                    title: 'Warehouse ceated successfully',
                    text: data.message
                })
            }
        },
        async deleteWarehouse () {
            const requestOptions = {
                method: "GET",
                headers: { 
                  'Accept': 'application/json',
                  'Authorization': 'Bearer '+this.token 
                },
            };
            const res = await fetch(process.env.VUE_APP_URL+'/warehouses/delete/'+this.warehouseId, requestOptions)
            const data = await res.json()
            if(data.status == true){
                Swal.fire({
                    icon: 'success',
                    title: 'Warehouse deleted successfully',
                    text: 'Warehouse deleted'
                })
                this.$router.back()
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Warehouse not deleted',
                    text: data.message
                })
            }
        },

        async removeSection (sectionId) {
            const requestOptions = {
                method: "GET",
                headers: { 
                  'Accept': 'application/json',
                  'Authorization': 'Bearer '+this.token 
                },
            };
            const res = await fetch(process.env.VUE_APP_URL+'/warehouses/delete/'+sectionId, requestOptions)
            const data = await res.json()
            if(data.status == true){
                Swal.fire({
                    icon: 'success',
                    title: 'Section deleted successfully',
                    text: 'Section deleted'
                })
                this.sections = data.sections
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Section not deleted',
                    text: data.message
                })
            }
        },
        
    }
  }
  </script>

<style lang="scss" scoped>


$primary: #d2d2d2;
$green: #4448B3;
$white: #ffffff;
  .check-button{
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .form-group{
        .components-button {
          display: flex;
          align-items: center;
          justify-content: left;
          span.switcher {
            position: relative;
            width: 156px;
            height: 56px;
            border-radius: 10px;
            input {
              appearance: none;
              position: relative;
              width: 130px;
              height: 40px;
              border-radius: 3px;
              border-color: #d2d2d2 !important;
              background: $primary;
              outline: none;
              font-family: 'Oswald', sans-serif;
              &:before, &:after {
                z-index: 2;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                color: $white;
              }
              &:before {
                content: 'Yes';
                left: 16px;
                font-weight: 600;
                font-size: 16px;
                line-height: 33px;
                color: #FFFFFF;
              }
              &:after {
                content: 'No';
                right: 16px;
                font-weight: 600;
                font-size: 17px;
                line-height: 33px;
                color: #FFFFFF;
              }
            }
            label {
              z-index: 1;
              position: absolute;
              width: 55px;
              height: 32px;
              margin: 0;
              top: 4px;
              left: 71px !important;
              border-radius: 3px;
            }
            &.switcher-1, &.switcher-2 {
              input {
                transition: .25s -.1s;
                &:checked {
                  background: $primary;
                  &:before {
                    color: $white;
                    transition: color .5s .2s;
                  }
                  &:after {
                    color: #ffffff;
                    transition: color .5s;
                  }
                  & + label {
                    left: 4px !important;
                    height: 30px !important;
                    background: $green;
                    transition: left .5s, right .4s .2s;
                  }
                }
                &:not(:checked) {
                  background: $primary;
                  transition: background .5s -.1s;
                  &:before {
                    color: #ffffff;
                    transition: color .5s;
                  }
                  &:after {
                    color: $white;
                    transition: color .5s .2s;
                  }
                  & + label {
                    left: 100px;
                    right: 10px;
                    height: 30px !important;
                    background: $green;
                    transition: left .4s .2s, right .5s, background .35s -.1s;
                  }
                }
              }
            }
          }
        }
      }
  </style>