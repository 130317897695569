<template>
  <div class="login">

    <Header :loginHeader="true" />

    <section id="login">
    <div class="container text-center">
        <div class="login-wrapper">
          <div class="img-wrapper">
            <img v-if="businessLogo" :src="businessLogo" style="width: 150px !important;" /> 
            <img v-else src="../../assets/images/ppt/pavicon_final_logo.png" alt=""  style="width: 150px">
          </div>
          <h5 style="font-family: helvetica" class="mb-5" v-if="profile?.systemName">{{profile.systemName}}</h5>
          <h5 style="font-family: helvetica" class="mb-5" v-else>Pavicon Warehouse & Inventory System</h5>

          <div class="form">
            <div class="form-group">
              <select class="full" v-model="selectModule" style="background-color: #dfdfdf !important; border-color: #dfdfdf !important;">
                <option value="">Select module</option>
                <option value="Warehouse" > Inventory and Warehouse</option>
              </select>
            </div>
            <div class="form-group">
              <input type="email" placeholder="Email" v-model="email" class="form-control">
            </div>
            <div class="form-group" v-if=!isForgotPassword>
              <input type="password" placeholder="Password" v-model="password" class="form-control">
            </div>
            <div class="form-group">
              <input type="submit" v-if="isForgotPassword == false" value="Login" @click="loginSubmit" class="btn-style btn-primary full" style="border: none;">
              <input type="submit" v-if="isForgotPassword" value="Reset Password" @click="resetSubmit" class="btn-style full" style="border: none">
            </div>
          </div>
          <a href="#" @="forgotPassword">{{ ForgotPasswordText }}</a>
        </div>
      </div>
    </section>

    <!--
    <p style="position: absolute;bottom: 0; margin:15px; ">(C) 2023 enterpriseOne UbuniWorks Solutions.</p>
    -->
  </div>
</template>

<script>
import Header from '../../components/Header.vue'
import Swal from 'sweetalert2'

export default {
  name: 'LoginPage',
  components: {
    Header,
  },
  data: () => ({
    LoginText: "Login",
    ForgotPasswordText: "Forgot Password?",
    isForgotPassword : false,
    email: "",
    password: "",
    user: "",
    selectModule:"",
    rules: [
        value => !!value || 'Required.',
        value => (value && value.length >= 4) || 'Min 4 characters',
    ],
    profile: {},
    businessLogo: ""
  }),


  mounted(){
    const profileData = localStorage.getItem('profile');
    this.profile = profileData && profileData !== 'undefined' ? JSON.parse(profileData) : null;
    this.businessLogo = this.profile?.businessLogo
    this.systemName = this.profile?.systemName
  },

  methods:{
    forgotPassword() {
      this.isForgotPassword = !this.isForgotPassword
      if(this.isForgotPassword) {
        this.LoginText = "Reset Password"
        this.ForgotPasswordText = "Back to Login"
      } else{
        this.LoginText = "Login"
        this.ForgotPasswordText = "Forgot Password?"
      }
    },

    async loginSubmit () {
      if(this.selectModule == ""){
        Swal.fire({
          icon: 'error',
          title: 'Login failed',
          text: 'Select a module'
        })
        return false;
      }
      const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ 
              email: this.email,
              password: this.password
          })
      };
      const res = await fetch(process.env.VUE_APP_URL+'/auth/login', requestOptions)
      const data = await res.json()
    
      if(data.status == true){
          localStorage.setItem('access_token', JSON.stringify(data.access_token))
          this.setupUser(data.access_token)

          
      }else{
          Swal.fire({
              icon: 'error',
              title: 'Login failed',
              text: data.message
          })
      }
    },


    async setupUser(token){
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+token },
        };
        const res = await fetch(process.env.VUE_APP_URL+'/users/profile', requestOptions)
        const data = await res.json()
        if(data.status == true){
          this.user = data.user;
          localStorage.setItem('permission', JSON.stringify(data.user.Permission))
          if(this.user.Permission.dashboard)
            this.$router.push("/home")
          else if(this.user.Permission.transaction)
            this.$router.push("/transaction")
          else this.$router.push("/inventory/stocks")
        }else{
          this.$router.push("/")
        }
    },





    async resetSubmit () {
      if(this.email == ""){
        Swal.fire({
          icon: 'error',
          title: 'Password Reset Failed',
          text: 'Enter an email address'
        })
        return false;
      }
      const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ 
              email: this.email
          })
      };
      const res = await fetch(process.env.VUE_APP_URL +'/auth/reset', requestOptions)
      const data = await res.json()
      if(data.status == true){
        Swal.fire({
            icon: 'success',
            title: 'Password Reset Successfully',
            text: data.message
        })
      }else{
          Swal.fire({
              icon: 'error',
              title: 'Password Reset Failed',
              text: data.message
          })
      }
    },
  }
 
}
</script>