<template>
    <div class="transaction">
  
      <Header :mainHeader="true"/>
  
      <section id="transaction-products">
        <div class="container">
  
          <h4 class="text-primary fw-bold">Process Orders</h4>
          <div class="warehouse-inventory-card-wrapper">
           <!-- <div class="warehouse-inventory-card">
              <p>Todays Dispatch Orders</p>
              <strong class="blue">{{ getTodayStats(dispatches) }}</strong>
            </div>
            <div class="warehouse-inventory-card">
              <p>Todays Pickup Orders</p>
              <strong class="blue">{{ getTodayStats(pickups) }}</strong>
            </div>-->
            <div class="warehouse-inventory-card">
              <p>This Month's Scheduled Pickups</p>
              <strong class="blue">{{ getThisMonthStats(scheduledPickups) }}</strong>
            </div>
  
          </div>
  
          <div class="filter-search"  style="margin-top: -30px;padding-left: 0px;">
            <div class="filter-search-header">
              <strong>Filter</strong>
              <p class="clear" @click="clear">Clear</p>
            </div>
            <div class="filter-search-body">
              <div class="form">
                <div class="row">
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Date From</label>
                      <input type="date" class="form-control" v-model="dateFrom">
                    </div>
                  </div>

                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Date To</label>
                      <input type="date" class="form-control" v-model="dateTo">
                    </div>
                  </div>

                  <div class="col-md-3 align-self-end">
                    <div class="form-group">
                      <label for="date">Pickup No. / Dispatch No.</label>
                      <input type="text" class="form-control" v-model="pickupNo">
                    </div>
                  </div>
                  
                  <div class="col-md-3 align-self-end">
                    <div class="form-group">
                      <label for="date">Project / Site</label>
                      <input type="text" class="form-control" v-model="project">
                    </div>
                  </div>
                  
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Priority</label>
                      <select class="full" v-model="status">
                        <option value="all">All</option>
                        <option value="np">Normal Pickup</option>
                        <option value="sp">Scheduled Pickup</option>
                        <option value="ud">Normal Dispatch</option>
                        <option value="nd">Urgent Dispatch</option>
                      </select>
                    </div>
                  </div>
                  
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Order Progress</label>
                      <select class="full" v-model="progress">
                        <option value="all">All</option>
                        <option value="pending">Pending</option>
                        <option value="completed">Complete</option>
                        <option value="cancelled">Cancelled</option>
                      </select>
                    </div>
                  </div>
                  
                  <div class="col-md-3 align-self-end">
                    <div class="form-group">
                      <label for="date">Purpose</label>
                      <select v-model="purposeId" class="full" id="purposes"  v-if="purposes.length > 0">
                          <option value="0">Any</option>
                          <option v-for="purpose in purposes" v-bind:Key="purpose.id" :value="purpose.id">
                              {{ purpose.purpose }} - {{ purpose.module }}
                          </option>
                      </select>
                      <div v-else>
                          <h5 for="" style="color: red !important;">No purpose found.</h5> 
                      </div>
                    </div>
                  </div>
                  
                  <div class="col-md-3 align-self-end">
                    <div class="form-group">
                      <label for="date">Requested/Issued By</label>
                      <select v-model="requestedId" class="full" id="requestedUsers"  v-if="users.length > 0">
                          <option value="0">Any</option>
                          <option v-for="user in users" v-bind:Key="user.id" :value="user.id">
                              {{ user.name }}
                          </option>
                      </select>
                      <div v-else>
                          <h5 for="" style="color: red !important;">No user found.</h5> 
                      </div>
                    </div>
                  </div>
                  
                  <div class="col-md-3 align-self-end">
                    <div class="form-group">
                      <label for="date">Dispatched By</label>
                      <select v-model="dispatchedId" class="full" id="dispatchedUsers"  v-if="users.length > 0">
                          <option value="0">Any</option>
                          <option v-for="user in users" v-bind:Key="user.id" :value="user.id">
                              {{ user.name }}
                          </option>
                      </select>
                      <div v-else>
                          <h5 for="" style="color: red !important;">No user found.</h5> 
                      </div>
                    </div>
                  </div>
                  
                  <div class="col-md-1 align-self-end">
                    <div class="form-group">
                      <button class="btn-style small" @click="search">Search</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div class="products-list transaction-products-list" style="margin-top: -20px;">
                <strong>Orders Table</strong>
                <!-- <keep-alive> -->
                <table class="table table-hover" id="ordersTable">
                    <thead>
                        <th>No.</th>
                        <th>Order No.</th>
                        <th>Created Date</th>
                        <th>Purpose</th>
                        <th>Requested By</th>
                        <th>Priority</th>
                        <th>Progress</th>
                        <th>Exception</th>
                        <th>Dispatched By</th>
                        <th>Project/Site</th>
                        <th>Action</th>
                    </thead>
                </table>
               
              <!-- </keep-alive> 
                <div v-if="dispatches.length < 1 || pickups.length < 1">
                  <label for="" class="alert alert-danger" style="margin: 0px auto; width: fit-content; display: block; border: none !important; border: none !important">No orders found</label>
                </div>-->
                 <LoaderItem v-if="loading"/>
          </div>
          
        </div>
      </section>
    </div>
  </template>
  
  <script>
  import { docFooter } from '../../utils/common'
  import { mapGetters, mapActions } from 'vuex'
  import Header from '../../components/Header';
  import LoaderItem from '../../components/LoaderItem'
  import 'datatables.net-vue3';
  import 'datatables.net-bs5';
  import "datatables.net-buttons-bs5";
  import "datatables.net-responsive-bs5";
  import $ from 'jquery';
  import 'jszip';
  import 'datatables.net-buttons/js/buttons.html5.js';
  import 'datatables.net-buttons/js/buttons.html5.js';
  import 'datatables.net-buttons/js/buttons.colVis.js';
  import 'datatables.net-buttons/js/buttons.print.js';
  import Swal from 'sweetalert2'

  export default {
    name: 'HomePurchases',
    components: {
      Header,
      LoaderItem
    },

    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        permission: JSON.parse(localStorage.getItem('permission')),
        userId: JSON.parse(localStorage.getItem('userId')),
        requestedByRoleId: null,
        urlServer:process.env.VUE_APP_URL,
        dispatches: [],
        pickups: [],
        scheduledPickups: [],
        todayDispatches:0,
        todayPickups:0,
        dateFrom: "",
        dateTo: "",
        pickupNo: "",
        project: "",
        status: "all",
        progress: "all",
        purposeId: "0",
        dispatchedId: "0",
        requestedId:"0",
        dataTable:  null,
        processedData: [],
        loading: false,
        pickupDatas: [],
        dispatchDatas: []
    }),

    computed:{
      ... mapGetters (["getUsers", "getPurposes"]),
      users () { return this.getUsers },
      purposes () { return this.getPurposes },
      userDepartment () { return this.permission.departmentId},
      userDepartmentRank () {return this.permission.deparmentRank}
    },

    mounted(){
      this.setupPickups()
      this.fetchUsers()
      this.fetchPurposes()
      window.cancelDispatch = this.cancelDispatch;
      window.cancelPickup = this.cancelPickup;
      window.deletePickup = this.deletePickup;
      window.deleteDispatch = this.deleteDispatch;
      window.showPickupComment = this.showPickupComment,
      window.showDispatchComment = this.showDispatchComment
    },

    methods:{
      ... mapActions(["fetchUsers", "fetchPurposes"]),
      preprocessDataDispatch(rawData) {
        return rawData.map(item => {
          let priority = "";
          let exception = " - ";
          let status =  "";

          if(item.isImmediate == true){
            priority = '<button class="btn btn-sm btn-danger" style="border-radius: 15px">Urgent</button>'
          } else {
            priority = '<button class="btn btn-sm btn-primary" style="border-radius: 15px; border-radius: 15px">Normal</button>'
          }

          if (item.status == 'processed') {
            status = '<button class="btn btn-sm btn-success" style="border: none; border-radius: 15px">Processed</button>'
          }else if(item.status == 'completed')
            status = '<button class="btn btn-sm btn-success" style="border: none; border-radius: 15px">Completed</button>'
          else if (item.status == 'cancelled') {
            status = '<button class="btn btn-sm btn-danger" style="border-radius: 15px !important">Cancelled</button>'
          }else if (item.status == 'active') {
            status = '<button class="btn btn-sm btn-warning" style="border-radius: 15px !important">Pending</button>'
          }else if (item.status == 'approved') {
            status = '<button class="btn btn-sm btn-success" style="border: none; border-radius: 15px">Approved</button>'
          } 
          else {
            status = `<button class="btn btn-sm btn-warning" style="text-transform: capitalize; border-radius: 15px !important">${item.status}</button>`
          }

          if(item.exception == 0 && item.status == 'completed') {
            exception = '<button class="btn btn-success btn-sm" style="margin-left: 5px; border-radius: 15px">OK</button>'
          } else if (item.exception == 1) {
            exception = '<button class="btn btn-danger btn-sm" style="margin-left: 5px;" border-radius: 15px>!</button>'
          } else {
            exception = ' - '
          }

          return {
            indexNo:"1",
            orderNo: item.dispatchNo,
            createdDate: this.formatDate(item.createdAt, "true"),
            purpose: item.purpose,
            RequestedBy: item.RequestedBy,
            priority,
            status,
            exception,
            DispatchedBy: item.DispatchedBy,
            project: item.project,
            action: '',
            statusRow: item.status,
            itemId: item.id,
            uploadDocument: item.uploadDocument,
            urlServer: this.urlServer,
            type: 'dispatch',
            departmentId: item.departmentId,
            departmentRank: item.departmentRank,
            userId: item.userId
          };
        });
      },

      reinitializeTable(){
        this.dataTable.clear()
        this.dataTable.rows.add(this.preprocessDataPickup(this.pickupDatas))
        this.dataTable.rows.add(this.preprocessDataDispatch(this.dispatchDatas))
        this.dataTable.draw()
      },

      showDispatchComment(dispatchId){
        const selectedDispatch = this.dispatches.filter(dispatch => dispatch.id == dispatchId)
        console.log("selected dispatch", selectedDispatch[0])
        Swal.fire({
          title: `<p style="background-color: #f2f2f2; padding: 20px">${selectedDispatch[0].comment}<p>`,
          html: `<span><b>Actioned By:</b> ${selectedDispatch[0].approvedBy}</span>`,
        });
      },


      showPickupComment(pickupId){
        const selectedPickup = this.pickups.filter(pickup => pickup.id == pickupId)
        console.log("selected pickup", selectedPickup[0])
        Swal.fire({
          title: `<p style="background-color: #f2f2f2; padding: 20px">${selectedPickup[0].comment}<p>`,
          html: `<span><b>Actioned By:</b> ${selectedPickup[0].approvedBy}</span>`,
        });
      },

      clear(){
        this.dateFrom = ""
        this.dateTo = ""
        this.pickupNo = ""
        this.project = ""
        this.status = "all"
        this.progress = "all"
        this.purposeId = 0
        this.dispatchedId = 0
        this.requestedId = 0
        this.reinitializeTable()
      },

      getTodayStats(array) {
        const today = new Date();
        const todayString = today.toISOString().split('T')[0]; 
        const todayValues = array.filter(obj => {
          const createdAtDate = new Date(obj.createdAt);
          const createdAtString = createdAtDate.toISOString().split('T')[0];
          return createdAtString === todayString;
        });
        return todayValues.length;
      },

     getThisMonthStats(array){
      const today = new Date();
      const thisMonth = today.getMonth();
      const thisYear = today.getFullYear();

      const thisMonthValues = array.filter(obj => {
          const createdAtDate = new Date(obj.createdAt);
          const createdAtMonth = createdAtDate.getMonth();
          const createdAtYear = createdAtDate.getFullYear();
          return createdAtMonth === thisMonth && createdAtYear === thisYear;
      });

      return thisMonthValues.length; 
    },

      preprocessDataPickup(rawData) {
        return rawData.map(item => {
          let priority = "";
          let exception = "";
          let status =  "";

          if(item.scheduled == true){
            priority = '<button class="btn btn-sm btn-danger" style="border-radius: 15px !important">Scheduled</button>'
          } else {
            priority = '<button class="btn btn-sm btn-primary" style="border-radius: 15px !important">Normal</button>'
          }

          if (item.status == 'completed') {
            status = '<button class="btn btn-sm btn-success" style="border-radius: 15px !important">Processed</button>'
          } else if (item.status == 'cancelled') {
            status = '<button class="btn btn-sm btn-danger" style="border-radius: 15px !important">Cancelled</button>'
          } else if (item.status == 'active') {
            status = '<button class="btn btn-sm btn-warning" style="border-radius: 15px !important">Pending</button>'
          }else if (item.status == 'approved') {
            status = '<button class="btn btn-sm btn-success" style="border: none; border-radius: 15px">Approved</button>'
          } 
           else {
            status = `<button class="btn btn-sm btn-warning" style="text-transform: capitalize; border-radius: 15px !important">${item.status}</button>`
          }

          if(item.exception == 0 && item.status == 'completed') {
            exception = '<button class="btn btn-success btn-sm" style="margin-left: 5px; border-radius: 15px !important" >OK</button>'
          } else if (item.exception == 1) {
            exception = '<button class="btn btn-danger btn-sm" style="margin-left: 5px; border-radius: 15px !important">!</button>'
          } else {
            exception = ' - '
          }

          return {
            indexNo:"1",
            orderNo: item.pickupNo,
            createdDate: this.formatDate(item.createdAt, "true"),
            purpose: item.purpose,
            RequestedBy: item.RequestedBy,
            priority,
            status,
            exception,
            DispatchedBy: item.DispatchedBy,
            project: item.project,
            action: '',
            statusRow: item.status,
            itemId: item.id,
            uploadDocument: item.uploadDocument,
            urlServer: this.urlServer,
            type: 'pickup',
            departmentId: item.departmentId,
            departmentRank: item.departmentRank,
            userId: item.userId


          };
        });
      },
      initializeDataTable() {
        try{
          this.dataTable = $(this.$el).find('#ordersTable').DataTable({
            language: {
              paginate: {
                previous: '<i class="fa fa-chevron-left"></i>', // Custom icon for previous button
                next: '<i class="fa fa-chevron-right"></i>', // Custom icon for next button
              },
            },
            dom: 'Bfrtip',
            buttons: [  
            'colvis',
            'pageLength', 
            {
              extend: 'csv',
              text: 'Export CSV',
              className: 'btn btn-primary',
              exportOptions: {
                columns: ':visible', 
              },
            },
            {
                extend: 'pdfHtml5',
                text: 'Export PDF',
                className: 'btn btn-primary',
                orientation: 'landscape', 
                exportOptions: {
                  columns: ':visible', 
                },
                customize: this.customizePDF,
              },
          ],
            data: this.processedData,
            columns: [
              { data: null },
              { data: 'orderNo' },
              { data: 'createdDate' },
              { data: 'purpose' }, 
              { data: 'RequestedBy' }, 
              { data: 'priority' }, 
              { data: 'status' }, 
              { data: 'exception' }, 
              { data: 'DispatchedBy' }, 
              { data: 'project' },
              { data: 'action', render: this.renderActionButtons }
            ],
            columnDefs: [
              {
                targets: 0,
                searchable: false,
                orderable: false,
                render: (data, type, row, meta) => {
                  return meta.row + 1;
                },
              },
            ],
          });
        } catch(error){
          console.log({error})
        }
      },


      renderActionButtons(data, type, row) {
        if (type === 'display') {
          let action =''
          if(row.statusRow != 'completed' && row.statusRow != 'cancelled') {
            if(row.type == 'dispatch' && this.permission.dispatchProcess && row.statusRow === 'approved'){
             // if(this.permission.id > 2){
                //if(row.departmentId === this.userDepartment && row.departmentRank == this.userDepartmentRank + 1 )
                // action =`<a style="border: 1px solid black; border-radius: 4px; padding: 5px 6px; margin-left: 21px !important" href="/orders/dispatch/process/${row.itemId}">Process</a>`
              //}
              //else 
                action =`<a style="border: 1px solid black; border-radius: 4px; padding: 5px 6px; margin-left: 21px !important" href="/orders/dispatch/process/${row.itemId}">Process</a>`
            }
            if(row.type == 'pickup'  && this.permission.pickupProcess && row.statusRow === 'approved'){
             // if(this.permission.id > 2){
                //if(row.departmentId === this.userDepartment &&  row.departmentRank == this.userDepartmentRank + 1)
                //  action =`<a style="border: 1px solid black; border-radius: 4px; padding: 5px 6px; margin-left: 21px !important" href="/orders/pickup/process/${row.itemId}">Process</a>`
             // }else
                action =`<a style="border: 1px solid black; border-radius: 4px; padding: 5px 6px; margin-left: 21px !important" href="/orders/pickup/process/${row.itemId}">Process</a>`
            }
          }

            action += `<div class="btn btn-small" role="group"><button type="button" class="btn btn-default btn-sm dropdown-toggle" style="border: 1px solid black; margin-left: 10px;" data-bs-toggle="dropdown" aria-expanded="false">Action</button><ul class="dropdown-menu">`
          
            if (row.type == 'dispatch') {
            if(row.uploadDocument){
              action += `<li><a class="dropdown-item" target="_blank" href="${row.urlServer}/files/${row.uploadDocument}">View Ref Document</a></li>`;
            }

            action += `<li><a class="dropdown-item" href="/orders/dispatch/pdf/${row.itemId}">View PDF</a></li>
            `
            if(row.statusRow == 'approved' || row.statusRow == 'rejected')
              action += `<li><a class="dropdown-item" href="#" onClick="showDispatchComment(${row.itemId})">View Comment</a></li>`



            if((this.permission.dispatchCancel || row.userId == this.userId  ) &&  row.statusRow != 'cancelled')
              action += `<li><a class="dropdown-item" href="#" onClick="cancelDispatch(${row.itemId})">Cancel</a></li>`;

            if (row.statusRow == 'completed') {
              action += `<li><a class="dropdown-item" href="/orders/dispatch/gate/${row.itemId}">View Gate Pass</a></li>`
            }
            
            if (row.statusRow == 'completed' && row.statusRow != 'cancelled' && this.permission.dispatchCancel) {
              action += `<li><a class="dropdown-item" href="#" onClick="cancelDispatch(${row.itemId})">Cancel</a></li>`
            }
          }
          
          if (row.type == 'pickup') {
            if(row.uploadDocument){
              action += `<li><a class="dropdown-item" target="_blank" href="${row.urlServer}/files/${row.uploadDocument}">View Ref Document</a></li>`;
            }

            action += `<li><a class="dropdown-item" href="/orders/pickup/pdf/${row.itemId}">View PDF</a></li>`;

            if(row.statusRow == 'approved' || row.statusRow == 'rejected')
            action += `<li><a class="dropdown-item" href="#" onClick="showPickupComment(${row.itemId})">View Comment</a></li>`

             if((this.permission.pickupCancel || row.userId == this.userId ) &&  row.statusRow != 'cancelled')
              action += `<li><a class="dropdown-item" href="#" onClick="cancelPickup(${row.itemId})">Cancel</a></li>`;


            if (row.statusRow == 'completed') {
              action += `<li><a class="dropdown-item" href="/orders/pickup/gate/${row.itemId}">View Gate Pass</a></li>`
            }
            
            if (row.statusRow == 'completed' && row.statusRow != 'cancelled' && this.permission.pickupCancel) {
              action += `<li><a class="dropdown-item" href="#" onClick="cancelPickup(${row.itemId})">Cancel</a></li>`
            }  
           
          }
          
          action += '</ul></div>';
          
          return action;
        }
        
        return data;
      },

      
      customizePDF(doc) {
      const profile = JSON.parse(localStorage.getItem('profile'))
      doc.pageMargins = [40, 40, 40, 40]; // Set margins for the entire page
      doc.defaultStyle.columnGap = 0
      doc.content[1].fillColor = '#f2f2f2'

      doc.header = () => {
        return {
          columns: [
            {
              width: '*',
              columns: [
                /* {
                     image: logoBase64,
                     width: 150, // Adjust the width as needed
                 },*/
                {
                  text: profile.businessName,
                  alignment: 'left',
                  margin: [40, 15, 0, 40], // Adjust margins as needed
                  color: '#FFA500', // Orange color
                  bold: true,
                  fontSize: 14,

                }
              ]
            },
            {
              width: '*',
              stack: [
                {
                  text: 'Stock Transfers',
                  alignment: 'right',
                  margin: [0, 15, 50, 0],
                  fontSize: 12,
                  color: '#4448B3'
                },
                {  
                  alignment: 'right',
                  margin: [300, 0, 0, 0],
                  width: '*',
                  columns: [
                    {
                      width: 'auto',
                      text: 'Date:',
                      alignment: 'right',
                      margin: [0, 0, 10, 0],
                      bold: true,
                    },
                    {
                      width: 'auto',
                      text: `${this.formatDate(new Date(), "true")}`,
                      alignment: 'right',
                      margin: [0, 0, 50, 0],
                      fontSize: 9
                    }
                  ]
                }
              ]
            }
          ],
          fillColor: '#000000'
        };
      },
      doc.footer = (currentPage, pageCount) =>  docFooter(currentPage, pageCount)
      doc.styles.title = {
        color: '#4448B3',
        fontSize: 13,
        margin: [80, 0, 0, 40],
      };
    },
    
    async setupPickups(){
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        try{
          const res = await fetch(process.env.VUE_APP_URL+'/pickups/approved', requestOptions)
          this.pickups = await res.json()
          this.pickups = this.pickups.filter(obj => obj.status == 'approved')
          this.pickupDatas = this.pickups
          this.scheduledPickups= this.pickups.filter(obj => obj.scheduled === 1);
          this.processedData = this.preprocessDataPickup(this.pickups);
          this.setupDispatches()
        }catch(err){
            console.log(err)
        }finally{
            this.loading = false
        }
      },

    async setupDispatches(){
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_URL+'/dispatches/approved', requestOptions)
        this.dispatches = await res.json()
        this.dispatches = this.dispatches.filter(obj => obj.status == 'approved')
        this.dispatchDatas= this.dispatches
        this.processedData = this.processedData.concat(this.preprocessDataDispatch(this.dispatches));
        this.initializeDataTable();
      },

      async search(){
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            body: JSON.stringify({ 
                dateFrom: this.dateFrom,
                dateTo: this.dateTo,
                pickupNo: this.pickupNo,
                dispatchNo: this.pickupNo,
                project: this.project,
                progress: this.progress,
                status: this.status,
                purposeId: this.purposeId,
                dispatchedId: this.dispatchedId,
                createdId: this.requestedId,
            })
        };
        
        
        const res = await fetch(process.env.VUE_APP_URL+'/pickups/search/approved', requestOptions)
        const data = await res.json()
        if(data.status == true){
            this.pickups = data.pickups
            this.pickups = this.pickups.filter(obj => obj.status == 'approved')
            this.dataTable.clear();
            this.dataTable.rows.add(this.preprocessDataPickup(this.pickups));
            this.dataTable.draw();
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Search failed. Try again',
                text: data.message
            })
        } 
    
        const requestOptionsD = {
            method: "POST",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            body: JSON.stringify({ 
                dateFrom: this.dateFrom,
                dateTo: this.dateTo,
                pickupNo: this.pickupNo,
                dispatchNo: this.pickupNo,
                project: this.project,
                progress: this.progress,
                status: this.status,
                purposeId: this.purposeId,
                dispatchedId: this.dispatchedId,
                createdId: this.requestedId,
            })
        };
        const resD = await fetch(process.env.VUE_APP_URL+'/dispatches/search/approved', requestOptionsD)
        const dataD = await resD.json()
        if(dataD.status == true){
            this.dispatches = dataD.dispatches
            this.dispatches = this.dispatches.filter(obj => obj.status == 'approved')
            this.dataTable.rows.add(this.preprocessDataDispatch(this.dispatches));
            this.dataTable.draw();
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Search failed. Try again',
                text: dataD.message
            })
        }
      },
      cancelPickup(pickupId){
          Swal.fire({
            title: 'Confirm Pickup Cancellation',
            text: "You won't be able to revert this!",
            icon: 'warning',
            input: 'text',
            inputLabel: 'Cancellation Reason',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, cancel it!',
            inputValidator: (value) => {
              if (!value) {
                return 'Reason for cancellation is required'
              }
            }
          }).then(function (result) {
            if (result.isConfirmed) {
              this.cancelPickupMethod(pickupId, result.value);
            }
          }.bind(this))
      },


    
      deleteDispatch(pickupId){
          Swal.fire({
            title: 'Confirm Dispatch Deletion',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
          }).then(function (result) {
            if (result.isConfirmed) {
              this.cancelPickupMethod(pickupId, result.value);
            }
          }.bind(this))
      },


      async deletePickupMethod (pickupId) {
        const requestOptions = {
            method: "DELETE",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_URL+'/pickups/delete/'+pickupId, requestOptions)
        console.log("Response", res)
        const data = await res.json()
        if(data.status == true){
            Swal.fire({
              position: 'top-center',
              icon: 'success',
              title: 'Pickup Note Deleted',
              showConfirmButton: false,
              timer: 1500
            })
            this.fetchPickups()
            this.getPickups()        
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Pickup Note Deletion Failed',
                text: data.message
            })
        }
      },


      async deleteDispatchMethod (pickupId, reason) {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            body: JSON.stringify({ 
              pickupNoteId: pickupId,
              reason: reason,
            })
        };
        const res = await fetch(process.env.VUE_APP_URL+'/pickups/cancel', requestOptions)
        const data = await res.json()
        console.log(data)
        if(data.status == true){
            Swal.fire({
              position: 'top-center',
              icon: 'success',
              title: 'Pickup Note Cancelled',
              showConfirmButton: false,
              timer: 1500
            })
            this.fetchPickups()
            this.getPickups()        
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Pickup Note Deletion Failed',
                text: data.message
            })
        }
      },
      async cancelPickupMethod (pickupId, reason) {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            body: JSON.stringify({ 
              pickupNoteId: pickupId,
              reason: reason,
            })
        };
        const res = await fetch(process.env.VUE_APP_URL+'/pickups/cancel', requestOptions)
        const data = await res.json()
        if(data.status == true){
            Swal.fire({
              position: 'top-center',
              icon: 'success',
              title: 'Pickup Note Cancelled',
              showConfirmButton: false,
              timer: 1500
            })
            this.fetchPickups()
            this.getPickups()        
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Pickup Note Cancellation Failed',
                text: data.message
            })
        }
      },

      

      cancelDispatch(dispatchId){
          Swal.fire({
            title: 'Confirm Dispatch Cancellation',
            text: "You won't be able to revert this!",
            icon: 'warning',
            input: 'text',
            inputLabel: 'Cancellation Reason',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, cancel it!',
            inputValidator: (value) => {
              if (!value) {
                return 'Reason for cancellation is required'
              }
            }
          }).then(function(result) {
            if (result.isConfirmed) {
              this.cancelDispatchMethod(dispatchId, result.value);
            }
          }.bind(this))
      },

        
    deletePickup(pickupId){
          Swal.fire({
            title: 'Confirm Pickup Deletion',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
          }).then(function (result) {
            if (result.isConfirmed) {
              this.deletePickupMethod(pickupId);
            }
          }.bind(this))
    },


      async cancelDispatchMethod (dispatchId, reason) {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            body: JSON.stringify({ 
              dispatchNoteId: dispatchId,
              reason: reason,
            })
        };
        const res = await fetch(process.env.VUE_APP_URL+'/dispatches/cancel', requestOptions)
        const data = await res.json()
        if(data.status == true){
            Swal.fire({
              position: 'top-center',
              icon: 'success',
              title: 'Dispatch Note Cancelled',
              showConfirmButton: false,
              timer: 1500
            })
            this.fetchDispatches()
            this.getDispatches()        
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Dispatch Note Cancellation Failed',
                text: data.message
            })
        }
      },
    }
  }
  </script>

  <style scoped>
  .purchases-list{
    padding: 20px;
    margin-top: 30px;
    box-shadow: rgba(49, 32, 104, 0.2) 0 2px 8px 0;
  }
  </style>