<template>
    <div class="transaction">
      <Header :mainHeader="true"/>
      
      <section id="transaction-products">
        <div class="container">
  
          <h3>Approvals</h3>
          <!--
            <button class="btn-style pull-right" style="margin-top: -50px;float:right;" @click="$router.push('/settings/approvals/pending')">Order Requests</button>
          -->
         
          <div class="warehouse-inventory-card-wrapper">

            <!--
              <div class="warehouse-inventory-card">
                <p>Pending PO Approvals</p>
                <strong class="blue">0</strong>
              </div>
            -->
          
            <div class="warehouse-inventory-card" @click="setActiveApproval('pending')" :style="{border: activeApproval === 'pending' ?  '1px solid #cccccc': ''}">
              <p>Pending Approvals</p>
              <strong class="blue">{{ this.pendingApprovals.length }}</strong>
            </div>

          
              <div class="warehouse-inventory-card" @click="setActiveApproval('urgent')" :style="{border: activeApproval === 'urgent' ?  '1px solid #cccccc': ''}">
                <p>Urgent Approvals</p>
                <strong class="red">{{ urgentApprovals.length }}</strong>
              </div>
            

            <div class="warehouse-inventory-card"  @click="setActiveApproval('processed')" :style="{border: activeApproval === 'processed' ?  '1px solid #cccccc': ''}">
              <p>Processed Requests</p>
              <strong class="green">{{ this.processedApprovals.length}}</strong>
            </div>
  
          </div>
  
  
          <div>
                <strong>Pending Approvals</strong>
                <table class="table table-hover" v-if="activeApprovals?.length > 0">
                    <thead>
                        <th>No.</th>
                        <th>Date</th>
                        <th>Priority</th>
                        <th>Request</th>
                        <th>Requested By</th>
                        <th v-if="activeApproval == 'processed'">Actioned By</th>
                        <th v-if="activeApproval == 'processed'">Actioned Date</th>
                        <th v-if="activeApproval == 'processed'">Comment</th>
                        <th>Status</th>
                        <th>Action</th>
                    </thead>
                    <tbody>
                        <tr v-for="(approval, index) in activeApprovals" v-bind:key="approval.id">
                            <td>{{ index+1 }}</td>
                            <td>{{  formatDate(approval.createdAt,"true") }}</td>
                            <td>
                              <button v-if="approval.priority == 'urgent'" class="btn btn-sm btn-danger" style="border-radius: 15px;">Urgent</button>
                              <button v-else class="btn btn-sm btn-primary" style="border-radius: 15px;">Normal</button>
                            </td>
                            <td v-if="approval.module == 'purchase'">Purchase Order</td>
                            <td v-else-if="approval.module == 'pickup'">Pickup Note</td>
                            <td v-else-if="approval.module == 'dispatch'">Dispatch Note</td>
                            <td v-else>{{ approval.module }}</td>
                            <td>{{  approval.requestedBy.name }}</td>
                            <td v-if="activeApproval == 'processed'">{{  approval.approvedBy.name }}</td>
                            <td v-if="activeApproval == 'processed'">{{  this.formatDate(approval.updatedAt, "true") }}</td>
                            <td v-if="activeApproval == 'processed'" class="capitalize-first">{{  approval.comment }}</td>
                            <td>
                              <button v-if="approval.type == 'approved'" class="btn btn-sm btn-success" style="border-radius: 15px;" >Approved</button>
                              <button v-if="approval.type == 'rejected'" class="btn btn-sm btn-danger" style="border-radius: 15px;">Rejected</button>
                              <button v-if="approval.type == 'pending'" class="btn btn-sm btn-warning" style="border-radius: 15px;">Pending</button>
                            </td>
                            <td>
                              <button v-if="approval.type =='pending'" class="btn btn-sm btn-primary" @click="processApproval(approval.id)">Approve</button>
                              <button v-if="approval.type =='pending'" class="btn btn-sm btn-danger" style="margin-left: 5px;" @click="rejectApproval(approval.id)">Reject</button>
                              <button class="btn btn-sm btn-default" style="border: 1px solid #000; margin-left: 5px;" @click="showApprovalModal(approval)">View</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                
                <div v-else>
                  <label for="" class="text-danger" style="margin: 0px ; width: fit-content; display: block;">No approval request found</label>
                </div>
          </div>
        </div>
      </section>

      <ApprovalModal v-if="isApprovalModalVisible" @close="closeApprovalModal" >
        <template v-slot:header>
          <h3>Request Details</h3>
          <h4>{{ modalTitle }}</h4> 
        </template>

        <template v-slot:body><div id="modalBody"  v-html="getApprovalModalBody(modalApproval)" ></div></template>

        <template v-slot:footer>
          <button class="btn btn-default" style="border: 1px solid #000" @click="closeApprovalModal"> Close</button>
          <button v-if="modalApproval?.type =='pending'" class="btn btn-primary" @click="processApproval(modalApproval?.id)">Approve</button>
          <button v-if="modalApproval?.type =='pending'" class="btn btn-danger" style="margin-left: 5px;" @click="rejectApproval(modalApproval?.id)">Reject</button>
        </template>
      </ApprovalModal>


    </div>
  </template>
  
  <script>
  import Swal from 'sweetalert2'
  import { mapGetters, mapActions } from 'vuex'
  import Header from '../../components/Header';
  import ApprovalModal from '../../components/ApprovalModal.vue'
  import { isProxy, toRaw } from 'vue';
  

  export default {
    name: 'ApprovalHome',
    components: {
      Header,
      ApprovalModal,
    },
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        isApprovalModalVisible: false,
        activeApproval: "pending",
        modalApproval: "",
        modalTitle:"pending ...",
        loading:false,
    }),
    computed:{
        ... mapGetters (["getApprovals"]),
        pendingApprovals () { return  this.filterApprovals(this.getApprovals, "pending") },
        approvals() { return this.getApprovals },
        processedApprovals() { return this.filterApprovals(this.getApprovals, "processed")},
        urgentApprovals() { return this.filterApprovals(this.getApprovals, "urgent")},
        activeApprovals() { return this.getActiveApprovals()}
    },
    methods:{
      ... mapActions(['fetchApprovals']),
      showApprovalModal(obj) {
       
        if(obj.module == 'DispatchNote'){
          this.$router.push(`/orders/dispatch/pdf/${obj.dispatchNote.id}`)
        }
        else if(obj.module == 'PickupNote'){
          this.$router.push(`/orders/pickup/pdf/${obj.pickupNote.id}`)
        }
        
        this.modalApproval = obj;
        this.isApprovalModalVisible = true;
        this.modalTitle = this.getApprovalModaltitle(this.modalApproval.module)
       // document.getElementById("modalBody").innerHTML = this.getApprovalModalBody(this.modalApproval)
      },

      getApprovalModaltitle(value) {
        switch (value){
          case "purchase": return "Purchase Order"
          case "StockReleased" : return "Release Reserved Stock"
          case "StockAdjustment" : return "Stock Adjustment"
          case "StockTransfer" : return "Stock Transfer"
          default: return ""
        }
      },

      setActiveApproval(arg){
        this.activeApproval = arg
      },

      getActiveApprovals(){
        switch(this.activeApproval){
          case "urgent":
            return this.urgentApprovals
          case "pending":
            return this.pendingApprovals
          case "processed":
            return this.processedApprovals
        }
      },
      
      getApprovalModalBody(data) {
        let approval=""
        if (isProxy(data)){
          approval = toRaw(data)
        } else { approval = data }
        switch (approval.module){
          case "purchase": return "<table class='table table-bordered'><tr><th>Status</th><td>"+approval.type+"</td></tr><tr><th>Purchase Order Number</th><td>"+approval.purchase?.purchaseNo
            +"</td></tr><tr><th>Department</th><td>"+approval.purchase?.department+"</td></tr><tr><th>Project</th><td>"+approval.purchase?.project
            +"</td></tr><tr><th>Supplier</th><td>"+approval.purchase?.Supplier?.name+" - "+approval.purchase?.Supplier?.phone
            +"</td></tr><tr><th>Purchase Order Created By</th><td>"+approval?.requestedBy?.name+"</td></tr></table><a class='btn btn-sm btn-primary' href=\"/purchases/pdf/"+
            approval.purchase.id+"\">View Purchase Order</a>"

          case "StockReleased" : return "<table class='table table-bordered'><tr><th>Priority</th><td style='text-transform: capitalize;'>"+approval.priority+"</td></tr><tr><th>Status</th><td style='text-transform: capitalize;'>"+
            approval.type+"</td></tr><tr><th>Product</th><td>"+approval.stockReservedActivity?.StockReserved?.Product?.name+" - "+approval.stockReservedActivity?.StockReserved?.Product?.code
              +"</td></tr><tr><th>Unit</th><td>"+approval.stockReservedActivity?.StockReserved?.Product?.unit.unit
            +"</td></tr><tr><th>Quantity Requested</th><td>"+approval.stockReservedActivity?.quantityReleased
            +"</td></tr><tr><th>Comment</th><td>"+approval.stockReservedActivity?.note
            +"</td></tr><tr><th>Quantity Reserved</th><td>"+approval.stockReservedActivity?.StockReserved?.quantity
            +"</td></tr><tr><th>Warehouse</th><td>"+approval.stockReservedActivity?.StockReserved?.Inventory?.Warehouse?.name +" - "+approval.stockReservedActivity?.StockReserved?.Inventory?.Warehouse?.description
            +"</td></tr><tr><th>Quantity Available</th><td>"+approval.stockReservedActivity?.StockReserved?.Inventory.quantity
              +"</td></tr><tr><th>Requested By</th><td>"+approval?.requestedBy?.name+"</td></tr></table>"

          case "StockAdjustment" : return "<table class='table table-bordered'><tr><th>Priority</th><td>"+approval.priority+"</td></tr><tr><th>Status</th><td>"+
            approval.type+"</td></tr><tr><th>Product</th><td>"+approval.stockAdjustment?.Product?.name+" - "+approval.stockAdjustment?.Product?.code
              +"</td></tr><tr><th>Unit</th><td>"+approval.stockAdjustment?.Product?.unit.unit
            +"</td></tr><tr><th>Type of Adjustment</th><td>"+approval.stockAdjustment?.type
            +"</td></tr><tr><th>Comment</th><td>"+approval.stockAdjustment?.comment
            +"</td></tr><tr><th>Current Quantity</th><td>"+approval.stockAdjustment?.oldQuantity
            +"</td></tr><tr><th>Quantity Change</th><td>"+approval.stockAdjustment?.quantity
            +"</td></tr><tr><th>New Quantity</th><td>"+approval.stockAdjustment?.newQuantity
            +"</td></tr><tr><th>Warehouse</th><td>"+approval.stockAdjustment?.Inventory?.Warehouse?.name +" - "+approval.stockAdjustment?.Inventory?.Warehouse?.description
            +"</td></tr><tr><th>Requested By</th><td>"+approval?.requestedBy?.name+"</td></tr></table>"

          case "StockTransfer" : return "<table class='table table-bordered'><tr><th>Priority</th><td>"+approval.priority+"</td></tr><tr><th>Status</th><td>"+
            approval.type+"</td></tr><tr><th>Product</th><td>"+approval.stockTransfer?.Product?.name+" - "+approval.stockTransfer?.Product?.code
              +"</td></tr><tr><th>Unit</th><td>"+approval.stockTransfer?.Product?.unit.unit
            +"</td></tr><tr><th>Quantity</th><td>"+approval.stockTransfer?.quantity
            +"</td></tr><tr><th>Comment</th><td>"+approval.stockTransfer?.comment
            +"</td></tr><tr><th>Old Warehouse</th><td>"+approval.stockTransfer?.Inventory?.Warehouse?.name +" - "+approval.stockTransfer?.Inventory?.Warehouse?.description
            +"</td></tr><tr><th>Quantity Available</th><td>"+approval.stockTransfer?.Inventory.quantity+"</td></tr><tr><th>New Warehouse</th><td>"+
              approval.stockTransfer?.Warehouse?.name+" - "+approval.stockTransfer?.Warehouse?.description+"</td></tr><tr><th>Requested By</th><td>"+
                approval?.requestedBy?.name+"</td></tr></table>"

          default: return "Details not found. Refresh the page and try again"
        }
      },

      closeApprovalModal() {
        this.isApprovalModalVisible = false;
      },

      async processApproval (approvalId) {
        this.closeApprovalModal()
        Swal.fire({
            title: "Approval Comment (Optional)",
            input: "text",
            inputAttributes: {
              autocapitalize: "off"
            },
            showCancelButton: true,
            confirmButtonText: "OK",
            showLoaderOnConfirm: true,
            preConfirm: async (comment) => {
              const res =  this.approve(approvalId, comment)
              return (await res).json()
            },       
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              icon: 'success',
              title: `Approval request accepted`,
            });
          }
        })

      },

      async approve(approvalId, comment){ 
        const requestOptions = {
              method: "POST",
              headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
              body: JSON.stringify({ 
                  approvalId,
                  comment
              })
          };
          return  await fetch(process.env.VUE_APP_URL+'/approvals', requestOptions)
          /*const data = await res.json()
          if(data.status == true){
            Swal.fire({
                icon: 'success',
                title: 'Approval request accepted',
                text: data.message
            })
            this.fetchApprovals();
          }else{
              Swal.fire({
                  icon: 'error',
                  title: 'Approvals not found',
                  text: data.message
              })
        }*/
      },

      
      async reject(approvalId, comment){
          const requestOptions = {
              method: "POST",
              headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
              body: JSON.stringify({ 
                  approvalId,
                  comment
              })
          };
          const res = await fetch(process.env.VUE_APP_URL+'/approvals/reject', requestOptions)
          console.log("Rejection Response", res)
          const data = await res.json()
          console.log("Response Data", data)
          if(data.status == true){
            Swal.fire({
                icon: 'success',
                title: 'Approval request Rejected',
                text: data.message
            })
            this.fetchApprovals();
          }else{
              Swal.fire({
                  icon: 'error',
                  title: 'Approvals not found',
                  text: data.message
              })
          }
      },

      async rejectApproval (approvalId) {
        this.closeApprovalModal()
        Swal.fire({
            title: "Reason for Reject/Comment (Optional)",
            input: "text",
            inputAttributes: {
              autocapitalize: "off"
            },
            showCancelButton: true,
            confirmButtonText: "OK",
            preConfirm: async (comment) => {
              this.reject(approvalId, comment)
            },       
        }) 
      },
    },




    mounted(){
        this.fetchApprovals()
    }
  }
  </script>

  <style scoped>
  .purchases-list{
    padding: 20px;
    margin-top: 30px;
    box-shadow: rgba(49, 32, 104, 0.2) 0 2px 8px 0;
  }

  </style>