<template>
    <div class="transaction">
  
      <Header :mainHeader="true"/>
  
      <section id="transaction-products">
        <div class="container">
  
          <h4 class="text-primary fw-bold" style="margin-bottom: 0px;">Marked for Return</h4>
          <div class="filter-search" style="margin-top:0 !important; padding-left: 0px;">
            <div class="filter-search-header">
              <strong>Filter</strong>
              <p class="clear" @click="clear">Clear</p>
            </div>
            <div class="filter-search-body">
              <div class="form">
                <div class="row">
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Date From</label>
                      <input type="date" class="form-control" v-model="dateFrom"  @change="filterTable" />
                    </div>
                  </div>
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Date To</label>
                      <input type="date" class="form-control" v-model="dateTo"   @change="filterTable"/>
                    </div>
                  </div>
                  <!--
                  <div class="col-md-2">
                    <button class="btn btn-primary" style="margin-top: 35px;" type="button" @click="search">Search</button>
                  </div>
                  -->
                  
                </div>
              </div>
            </div>
          </div>

          <div class="products-list transaction-products-list" style="margin-top: 0px;">
            <strong style="margin-top: 0;">Item List</strong>
            <div id="buttonsContainer"></div>
            <keep-alive>
            <table class="table table-hover display" id="itemsTable">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Reference No.</th>
                  <th>Date Issued</th>
                  <th>Date Returned</th>
                  <th>Product Description</th>
                  <th>Issued To</th>
                  <th>Quantity</th>
                  <th>Project</th>
                  <th>Status</th>
                  <th>Comment</th>
                  <th>Action</th>
                </tr>
              </thead>
              
            </table>
          </keep-alive>
          <LoaderItem v-if="loading"/>
            <div v-if="activities.length < 1">
              <!--<label for="" class="alert alert-danger" style="margin: 0px auto; width: fit-content; display: block; border: none !important">No log found</label>-->
            </div>

          </div>

        </div>
      </section>


<Modal v-if="isVisible" @close="closeModal" >
  <template v-slot:header>
    <h4 class="fw-bold text-primary">Returned Items</h4>
  </template>

  <template v-slot:body>
    <div class="row">
      <div class="col-sm-12 mt-1">
          <label for="item-code">Kindly select BPN</label>
          <select v-model="batchId" class="col-7 form-control" id="batches"  v-if="batches.length > 0">
              <option v-for="batch in batches" v-bind:Key="batch.id" :value="batch.id">
                  {{ batch.batchNo }}
              </option>
          </select>
      </div>
      <div class="form-group col-12">
        <label for="item-code">Comment</label>
        <input type="text" class="form-control" v-model="comment">
      </div>
    </div>
  </template>

  <template v-slot:footer>
    <button class="btn-style small" style="border: 1px solid black; float: right;" @click="confirmReturn">Confirm</button>
    <button class="btn btn-default" style="border: 1px solid black; float: right; margin-right: 10px;" @click="isVisible =false">Cancel</button>
  </template>
</Modal>

    </div>
  </template>
  
  <script>
  
  import Swal from 'sweetalert2'
  import Header from '../../components/Header';
  import LoaderItem from '../../components/LoaderItem'
  import Modal from '../../components/Modal.vue';
  import 'datatables.net-vue3';
  import 'datatables.net-bs5';
  import "datatables.net-buttons-bs5";
  import "datatables.net-responsive-bs5";
  import $ from 'jquery';
  import 'jszip';
  import 'datatables.net-buttons/js/buttons.html5.js';
  import 'datatables.net-buttons/js/buttons.html5.js';
  import 'datatables.net-buttons/js/buttons.colVis.js';
  import 'datatables.net-buttons/js/buttons.print.js';
  import { docFooter } from '@/utils/common';
 
    
  export default {
    name: 'StockTransfers',
    components: {
    Header,
    Modal,
    LoaderItem
},
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        activities: [],
        batches: [],
        batchId:"",
        comment: "",
        dateFrom:"",
        dateTo: "",
        markedId: "",
        isVisible:false,
        dataTable:  null,
        processedData: [],
        loading: false, 
        activitiesDatas: []
    }),
    mounted(){
        this.getActivities();
        this.getBatches();

        window.openModal = this.openModal;
        window.remind = this.remind;
    },
    // beforeUnmount() {
    //   if (this.dataTable) {
    //     this.dataTable.destroy();
    //   }
    // },
    methods:{
      closeModal() {
        this.isVisible = false;
      },
      preprocessData(rawData) {
        return rawData.map(item => {
          let referenceNo = "";
          let dateReturned = " - ";
          let issuedTo = "";
          let quantity = " - ";
          let status =  "";
          let action = '<div class="btn-group" role="group"><button type="button" class="btn-sm btn-style small dropdown-toggle" style="border: 1px solid black; margin-left: 10px;" data-bs-toggle="dropdown" aria-expanded="false">Action</button><ul class="dropdown-menu"><li><a class="dropdown-item" href="/inventory/products/'+item.ProductId+'">View Product</a></li>';

          if(item?.reference){
            referenceNo = item.reference
          }
          if(item?.BatchNo){
            referenceNo += " - "+item.BatchNo
          }
          if(item?.assigneCompany) {
            issuedTo=item.assigneCompany
          }
          if(item?.AssignedName) {
            issuedTo = item.AssignedName
          }
          if(item?.PickupQty) {
            quantity =  item.PickupQty
          }
          if(item?.DispatchQty) {
            quantity =  item.DispatchQty
          }
          if(item.status == 'active'){
            status = '<button class="btn btn-warning btn-sm" style="border-radius: 15px" >Unreturned</button>'
          }
          if(item.status == 'done'){
            status = '<button class="btn btn-success btn-sm" style="border-radius: 15px"  >Returned</button>'
          }
          if(item.status != 'done'){
            action += '<li><a class="dropdown-item" href="#"  onclick="openModal('+item.id+')">Mark as Returned</a></li>'
            action += '<li><a class="dropdown-item" href="#" @click="this.remind('+item.id+')">Remind</a></li>'
          }
          action += '</ul></div>';

          return {
            indexNo:"1",
            referenceNo,
            dateIssued: this.formatDate(item.createdAt),
            dateReturned,
            productName: item.ProductName,
            issuedTo,
            quantity,
            status,
            project: item.project,
            comment: item.comment,
            action,
            ProductId: item.ProductId,
            itemId: item.id,
            statusRow: item.status
          };
        });
      },


      convertDate(dateString) {
        const parts = dateString.split("/")
        const newDate = `${parts[1]}/${parts[0]}/${parts[2]}`;
        return newDate;
      },


      filterTable() {
        if (!this.dateFrom || !this.dateTo) {
          console.error("Invalid date inputs");
          return;
        }

        const fromDate = new Date(this.dateFrom);
        const toDate = new Date(this.dateTo);

        if (isNaN(fromDate) || isNaN(toDate)) {
          console.error("Invalid date values");
          return;
        }

        $.fn.dataTable.ext.search.push((settings, data, dataIndex) => {
          const dateStr = data[2]; 
          const parsedDate = this.convertDate(dateStr);
          const date = new Date(parsedDate);
         // console.log(settings)
          console.log(dataIndex)
          if (!isNaN(date)) {
            //console.log("From Date", fromDate)
            //console.log("Date", date)
           // console.log("To Date", toDate)

            return date >= fromDate && date <= toDate;
          }
          return false;
        });
        this.dataTable.draw();
        $.fn.dataTable.ext.search.pop();
      },

      reinitializeTable(){
        this.dataTable.clear()
        this.dataTable.rows.add(this.preprocessData(this.activitiesDatas))
        this.dataTable.draw()
      },


      clear(){
        this.dateFrom = ""
        this.dateTo = ""
        this.reinitializeTable()
      },


      initializeDataTable() {
        try{
          this.dataTable = $(this.$el).find('#itemsTable').DataTable({
            language: {
              paginate: {
                previous: '<i class="fa fa-chevron-left"></i>', // Custom icon for previous button
                next: '<i class="fa fa-chevron-right"></i>', // Custom icon for next button
              },
            },
            dom: 'Bfrtip',
            buttons: [  
            'colvis',
            'pageLength', 
            {
              extend: 'csv',
              text: 'Export CSV',
              className: 'btn btn-primary',
              exportOptions: {
                columns: ':visible', 
              },
            },
            {
                extend: 'pdfHtml5',
                text: 'Export PDF',
                className: 'btn btn-primary',
                orientation: 'landscape', 
                exportOptions: {
                  columns: ':visible', 
                },
                customize: this.customizePDF,
              },
          ],
            data: this.processedData,
            columns: [
              { data: null },
              { data: 'referenceNo' },
              { data: 'dateIssued' },
              { data: 'dateReturned' }, 
              { data: 'productName' }, 
              { data: 'issuedTo' }, 
              { data: 'quantity' }, 
              { data: 'project' }, 
              { data: 'status' }, 
              { data: 'comment' },
              { data: 'action', render: this.renderActionButtons }
            ],
            columnDefs: [
              {
                targets: 0,
                searchable: false,
                orderable: false,
                render: (data, type, row, meta) => {
                  return meta.row + 1;
                },
              },
            ],
          });
        } catch(error){
          //console.log({error})
        }
      },
      renderActionButtons(data, type, row) {
        if (type === 'display') {
          let action = `<div class="btn-group" role="group"><button type="button" class="btn-sm btn-style small dropdown-toggle" style="border: 1px solid black; margin-left: 10px;" data-bs-toggle="dropdown" aria-expanded="false">Action</button><ul class="dropdown-menu"><li><a class="dropdown-item" href="/inventory/products/${row.ProductId}">View Product</a></li>`;
          
          if (row.statusRow != 'done') {
            action += `<li><a class="dropdown-item" href="#" onClick="openModal(${row.itemId})">Mark as Returned</a></li>`;
            action += `<li><a class="dropdown-item" href="#" onclick="remind(${row.itemId})">Remind</a></li>`;
          }
          
          action += '</ul></div>';
          
          return action;
        }
        
        return data;
      },

      
    convertToBase64(imageUrl) {
      fetch(imageUrl)
        .then(response => response.blob())
        .then(blob => {
          const reader = new FileReader();
          reader.onloadend = () => {
            this.base64Image = reader.result;
          };
          reader.readAsDataURL(blob);
        });
    },


    customizePDF(doc) {
      //const logoBase64 = this.convertToBase64(logo)
      //console.log(logoBase64)
      const profile = JSON.parse(localStorage.getItem('profile'))
      doc.pageMargins = [40, 40, 40, 40]; // Set margins for the entire page
      doc.defaultStyle.columnGap = 0
      doc.content[1].fillColor = '#f2f2f2'
      
      doc.header = () => {
        return {
          columns: [
            {
              width: '*',
              columns: [
                /* {
                     image: logoBase64,
                     width: 150, // Adjust the width as needed
                 },*/
                {
                  text: profile?.businessName,
                  alignment: 'left',
                  margin: [40, 15, 0, 40], // Adjust margins as needed
                  color: '#FFA500', // Orange color
                  bold: true,
                  fontSize: 14,

                }
              ]
            },
            {
              width: '*',
              stack: [
                {
                  text: 'Marked Returns',
                  alignment: 'right',
                  margin: [0, 15, 50, 0],
                  fontSize: 12,
                  color: '#4448B3'
                },
                {  
                  alignment: 'right',
                  margin: [300, 0, 0, 0],
                  width: '*',
                  columns: [
                    {
                      width: 'auto',
                      text: 'Date:',
                      alignment: 'right',
                      margin: [0, 0, 10, 0],
                      bold: true,
                    },
                    {
                      width: 'auto',
                      text: `${this.formatDate(new Date(), "true")}`,
                      alignment: 'right',
                      margin: [0, 0, 50, 0],
                      fontSize: 9
                    }
                  ]
                }
              ]
            }
          ],
          fillColor: '#000000'
        };
      },

      doc.footer = (currentPage, pageCount) => docFooter(currentPage, pageCount)

      doc.styles.title = {
        color: '#4448B3',
        fontSize: 13,
        margin: [80, 0, 0, 40],
      };
      },





        openModal(itemId){
            this.isVisible =true,
            this.markedId = itemId
        },
        async confirmReturn() {
          const requestOptions = {
              method: "POST",
              headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
              body: JSON.stringify({ 
                comment: this.comment,
                batchId: this.batchId,
                markedId: this.markedId,
              })
          };
          const res = await fetch(process.env.VUE_APP_URL+'/markedreturn', requestOptions)
          const data = await res.json()
          if(data.status == true){
            this.isVisible = false
            this.comment = ""
            this.batchId = ""
            this.markedId = ""
            this.clear()
          }else{
            Swal.fire({
                icon: 'error',
                title: 'Items not returned. Try again',
                text: data.message
            })
          }
        },
        async getActivities () {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            };

            try{
              this.loading = true
              const res = await fetch(process.env.VUE_APP_URL+'/markedreturn', requestOptions)
              const data = await res.json()
              if(data.status == true){
                  this.activities = data.stocks
                  this.activitiesDatas = this.activities
                  this.processedData = this.preprocessData(data.stocks);
                  this.initializeDataTable();
              }
            }catch(err){
                console.log(err)
            }finally{
                this.loading = false
            }
        },
        async search(){
          const requestOptions = {
              method: "POST",
              headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
              body: JSON.stringify({ 
                  dateFrom: this.dateFrom,
                  dateTo: this.dateTo
              })
          };
          const res = await fetch(process.env.VUE_APP_URL+'/markedreturn/search', requestOptions)
          const data = await res.json()
          if(data.status == true){
            this.activities = data.stocks
            this.processedData = this.preprocessData(data.stocks);
            // this.initializeDataTable();
          }else{
            Swal.fire({
                icon: 'error',
                title: 'Stock List Marked for Return not found',
                text: data.message
            })
          }
        },
        async getBatches () {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            };
            const res = await fetch(process.env.VUE_APP_URL+'/batches/batchno', requestOptions)
            const data = await res.json()
            if(data.status == true){
                this.batches = data.batches
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Try again',
                    text: data.message
                })
            }
        },
        async remind (id) {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            };
            const res = await fetch(process.env.VUE_APP_URL+'/markedreturn/remind/'+id, requestOptions)
            const data = await res.json()
            if(data.status == true){
              Swal.fire({
                icon: 'success',
                title: 'Remind Message Sent',
                text: data.message
              })
            }else{
              Swal.fire({
                  icon: 'error',
                  title: 'Remind Message Failed',
                  text: data.message
              })
            }
        },
    }
  }
  </script>

<style>
@import 'datatables.net-bs5';
@import 'datatables.net-buttons-bs5';
</style>